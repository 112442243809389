<template>
    <Modalog :has-header="true" size="medium">
        <template #title>Did You Buy This Gift?</template>
        <template #content>
            <div class="dialog__item">
                <div class="dialog__item-image">
                    <img :src="item.image_url.img_1x" :alt="item.name"/>
                </div>
                <div>
                    <div class="dialog__item-name">{{ item.name }}</div>
                    <!--@todo MR: Currency-->
                    <div class="dialog__item-price">${{ item.price }}</div>
                    <div class="dialog__item-retailer" v-if="retailerName">{{ retailerName }}</div>
                </div>
            </div>
            <div class="dialog__qty">
                <div class="dialog__qty--title">How many did you buy?</div>
                <div class="dialog__qty--count">
                    <label for="quantity-purchased" class="visuallyhidden">Quantity purchased</label>
                    <div class="select">
                        <select name="quantity-purchased" id="quantity-purchased" :disabled="isQtySelectDisabled" v-model="qtyConfirming">
                            <option v-for="n in qtyConfirmable" :value="n" v-text="n"></option>
                        </select>
                    </div>
                    <div class="dialog__qty--req">of <strong>{{ item.qty_desired }} requested</strong>.</div>
                </div>
            </div>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="confirmPurchase()" :disabled="modalogStore.isConfirmDisabled">Confirm</button>
        </template>
    </Modalog>
</template>
<script setup>
import { computed, onMounted, onUpdated, ref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import { range } from 'lodash';
import { router } from "@inertiajs/vue3";
import Modalog from "@/Components/Modalogs/Modalog.vue";

// Stores
const modalogStore = useModalogStore();

// Props
const props = defineProps({
    item: {
        type: Object,
        default: {},
    },
});

// State
const isConfirmDisabled = ref(false);
const qtyConfirming = ref(0);

// Hooks
onMounted(() => {
   modalogStore.isConfirmDisabled = false;
   setQtyConfirming();
});

onUpdated(() => {
    setQtyConfirming();
})

// Computed
const isQtySelectDisabled = computed(() => {
    return !props.item.qty_purchased && qtyRemaining.value === 1;
});

const multipleItemsRemaining = computed(() => {
    return props.item.qty_remaining > 1;
});

const product = computed(() => {
    return props.item.product;
});

/**
 * This looks insane, but it's really here for a good reason.
 * @see GiftConfirmationController.php
 * We're trying to reasonably calculate the number of items a user should
 * be able to confirm as purchased.
 */
const qtyConfirmable = computed(() => {
    // If I purchased 0 items,
    // the dropdown should be from 1 to item.qty_remaining
    if (props.item.qty_purchased === 0) {
        return range(1, qtyRemaining.value + 1) // zero-indexed
    } else {
        // If I purchased 1 or more
        // And the qty_remaining === 0
        // Then, the range is 0 to item.qty_purchased
        if (qtyRemaining.value === 0) {
            return range(0, props.item.qty_purchased + 1) // zero-indexed
        } else {
            // If the number of items remaining is more than 0,
            // then the dropdown should be from 0 to the number of items I purchased + the number
            // of items still remaining.
            return range(0, (props.item.qty_purchased + qtyRemaining.value) + 1) // zero-indexed
        }
    }
});

const qtyDesired = computed(() => {
    return props.item.qty_desired;
});

const qtyReceived = computed(() => {
    return Number(props.item.qty_desired - props.item.qty_remaining);
});

const qtyRemaining = computed(() => {
    return props.item.qty_remaining;
});

const retailer = computed(() => {
    return props.item.product.retailer;
});

const retailerName = computed(() => {
    return retailer.value.name !== 'custom_item'
        ? (retailer.value.name ? retailer.value.name : retailer.value.hostname)
        : false
});

// Methods
function confirmPurchase() {
    modalogStore.isThrobbing = true;

    axios.post(route('gifts.confirm', { item: props.item.uuid }), { qty: qtyConfirming.value })
        .catch((error) => {
            console.log(error);
        })
        .then(() => {
        })
        .finally(() => {
            modalogStore.isThrobbing = false;
            modalogStore.closeModalog();
            router.reload({ only: ['item'] });
            setQtyConfirming();
        });
};

/**
 * Reset the `qtyConfirming` state
 */
function setQtyConfirming () {
    qtyConfirming.value = Number(props.item.qty_purchased === 0 ? 1 : props.item.qty_purchased);
};
</script>
