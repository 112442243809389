/**
 * Checks if current auth user is also owner
 * @returns { boolean }
 */
import { usePage } from "@inertiajs/vue3";

export function useIsOwner() {
    let isOwner = false;

    const user = usePage().props.auth?.user?.data;
    const owner = usePage().props.owner?.data;

    if (user && owner) {
        isOwner = user?.uuid === owner?.uuid;
    }

    return isOwner;
}
