<template>
    <Head :title="`Your Bookmarks`" />

    <BookmarksLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar is-mobile="true" />
        </template>
        <template #content>
            <header class="grid-header grid-header--basic">
                <div class="grid-info__info">
                    <h2 class="grid-header__title">Bookmarks</h2>
                    <div class="grid-header__description">
                        <p>Keep track of your friends' wish lists for quick access. Select the gear icon while viewing a
                            friend's list and then select "Add to Bookmarks".</p>
                    </div>
                </div>
            </header>
                <div class="hint-panel has-shadow" v-if="!bookmarks.length">
                    <div class="hint-panel__body">
                        <div class="hint-panel__header">
                            <div class="hint-panel__gh-logo">
                                <GHLogoGift />
                            </div>
                            <div class="hint-panel__title">
                                You don't have any bookmarked wish lists yet.
                            </div>
                        </div>
                    </div>
                </div>
            <section class="list-grouping whitespace-bottom">
                <div class="list-grid">
                  <ListCard
                    :list="bookmark"
                    :logged-in="true"
                    :owner="bookmark.owner"
                    :key="bookmark.uuid"
                    @click.stop
                    v-for="bookmark of $page.props.bookmarks.data"
                />
                </div>
            </section>
        </template>
    </BookmarksLayout>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import ItemCard from "@/Components/cards/Item/ItemCard.vue";
import { useAuthUser } from "@/composables/useAuthUser";
import { useWishList } from '@/composables/useWishList';
import BookmarksLayout from "@/Layouts/BookmarksLayout.vue";
import Sidebar from "@/Pages/MyGiftHero/Partials/Sidebar.vue";
import GHLogoGift from "~/svg/gh-logo-gift.svg";
import ListCard from "@/Components/cards/ListCard.vue";

const user =  useAuthUser();
const { giftlist, isExpertList } = useWishList();
const bookmarks = computed(() => usePage().props.bookmarks.data);

const isBookmarksView = computed(() => {
    return route().current() === 'bookmarks';
});

const itemCardComponent = computed(() => {
    return ItemCard;
})
</script>
