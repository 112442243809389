/**
 * Card Option Menus store.
 */
import { defineStore } from "pinia";
import { onMounted, onUnmounted, ref } from 'vue';

export const useCardOptionsMenuStore = defineStore('card-options-menu', () => {
    const openInstance = ref('');

    function closeAll (delay = null) {
        return new Promise ( (resolve, reject) => {
            if (delay) {
                setTimeout(() => {
                    openInstance.value = '';
                    resolve();
                }, delay);
            } else {
                openInstance.value = '';
                resolve();
            }
        })
    }

    function escapeClose(event) {
        if (event.code === "Escape") {
            openInstance.value = '';
        }
    }

    function updateOpenInstance(value) {
        openInstance.value = value;
    }

    onMounted(() => window.addEventListener("keydown", escapeClose));
    onUnmounted(() => window.removeEventListener("keydown", escapeClose));

    return {
        closeAll,
        openInstance,
        updateOpenInstance,
    }
});
