<template>
    <Modalog :has-header="false">
        <template #content>
            <h2 class="dialog__title dialog__title--no-header">{{ actionToUppercase }} {{ user.presentation_name }} (@{{ user.username }})</h2>
            <template v-if="action === 'block'">
                <p>Are you sure you want to block <strong>{{ user.presentation_name }} (@{{ user.username }})</strong>? They will not be able to view your wish lists once they are blocked.</p>
            </template>
            <template v-else-if="action === 'unblock'">
                <p>Are you sure you want to unblock <strong>{{ user.presentation_name }} (@{{ user.username }})</strong>? They will be able to view your wish lists once they are unblocked.</p>
            </template>
            <aside class="hint-panel hint-panel__quick-tip">
                <i class="icon"><icon-hint></icon-hint></i>
                <div class="content">
                    <p><a href="https://support.gifthero.com/page/sharing-and-connecting#how-does-user-blocking-work" title="How does user blocking work?" target="_blank" rel="noopener">How does user blocking work?</a></p>
                </div>
            </aside>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" :disabled="isSubmitting" @click="handleClick">Ok</button>
        </template>
    </Modalog>
</template>
<script setup>
import { computed, ref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import { router, usePage } from "@inertiajs/vue3";
import { useApiService } from "@/services/useApiService";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import IconHint from '~/svg/icon-hint.svg'

// Props
const props = defineProps({
    action: {
        type: String,
        required: true,
        validator: function (value) {
            return [
                'block',
                'unblock'
            ].indexOf(value) !== -1
        }
    },
    redirectAfterBlocking: {
        type: Boolean,
        default: false
    },
    user: {
        type: Object,
        default: null
    },
});

// State
const isSubmitting = ref(false);

// Stores
const modalogStore = useModalogStore();

// Services
const { blockUser, unblockUser } = useApiService();

// Computed
const actionToUppercase = computed(() => {
    return ( props.action.charAt(0).toUpperCase() + props.action.slice(1) )
});

const currentView = computed(() => {
    return usePage().component;
});

const isBlocked = computed(() => {
    return props.user.hasOwnProperty('is_blocked') ? props.user.is_blocked : null;
});

const isFindFriendsView = computed(() => {
    return route().current() === 'friends.find';
    // return ['followers-mine', 'following-mine', 'friends-find'].includes(this.currentView);
});

// Methods
function handleClick () {
    isSubmitting.value = true;

    if (isBlocked.value) {
        // Unblock
        unblockUser(props.user.uuid)
            .then(() => {
                modalogStore.closeModalog();

                props.user.is_blocked = false;
                props.user.is_processing = true;
                props.user.is_processing_text = `${props.user.presentation_name} is no longer blocked.`;
            })
            .catch(error => console.error(error))
            .finally(() => {
                router.reload();
            });
    } else {
        // Block
        blockUser(props.user.uuid)
            .then(() => {
                modalogStore.closeModalog();

                props.user.is_blocked = true;
                props.user.is_processing = true;
                props.user.is_processing_text = `${props.user.presentation_name} is now blocked from viewing your wish lists.`;

                // Redirect?
                if (props.redirectAfterBlocking) window.location = '/';
            })
            .catch(error => console.error(error))
            .finally(() => {
                router.reload()
            });
    }
};
</script>
