<template>
    <button class="popover__option strip-btn is-danger" v-if="canBlockUser" @click="handleClick" :title="buttonText" :aria-description="buttonText">
        <span class="icon"><icon-option-block></icon-option-block></span>
        <span class="label">{{ buttonText }}</span>
    </button>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import IconOptionBlock from '~/svg/ui-option-block.svg'

// Props
const props = defineProps({
    redirectAfterBlocking: {
        type: Boolean,
        default: false
    },
    user: {
        type: Object,
        default: null
    }
});

// Emits
const emits = defineEmits(['confirm-block-user', 'confirm-unblock-user']);

// Stores
const modalogStore = useModalogStore();

// Computed
const buttonText = computed(() => {
    return (isBlocked.value ? 'Unblock' : 'Block') + ' @' + props.user.username;
});

const canBlockUser = computed(() => {
    return (isLoggedIn.value && !isGiftHero.value);
});

const isBlocked = computed(() => {
    return props.user.is_blocked;
});

const isGiftHero = computed(() => {
    return props.user.is_gift_hero;
});

const isLoggedIn = computed(() => {
    return usePage().props.auth.user;
});

// Methods
function handleClick () {
    modalogStore.showModalog({
        type: 'ModalogBlockUnblockUserConfirmation',
        props: {
            action: isBlocked.value ? 'unblock' : 'block',
            isConfirmDisabled: false,
            redirectAfterBlocking: props.redirectAfterBlocking,
            user: props.user
        }
    });

    // this.isBlocked ? this.unblockUser() : this.blockUser()
};
</script>
