<template>
    <Transition name="fade">
        <div
            class="card card__list list-grid__item"
            v-if="list && !isHidden"
            v-click-outside="clickOutside"
        >
            <div class="card__container">
                <!--Processing text-->
                <div class="card__processing" v-if="isProcessing">
                    <div class="card__processing-label">
                        {{ isProcessingText }}
                    </div>
                </div>
                <!--Bookmarks-->
                <template v-if="isBookmarksView">
                    <div class="card__bookmark-owner">
                        <div class="owner-avatar">
                            <a
                                :href="owner.open_graph.url"
                                :title="owner.username"
                                v-if="owner"
                            ><img
                                    :src="owner.avatar.profile_photo"
                                    height="26"
                                    width="26"
                                    :alt="owner.presentation_name"
                            /></a>
                        </div>
                        <div class="owner-message">
                            List by
                            <a
                                :href="owner.open_graph.url"
                                :title="owner.username"
                                v-if="owner"
                                >@{{ owner.username }}</a
                            >
                        </div>
                    </div>
                </template>
                <!--Card Anchor-->
                <Link :href="route('list.show', { giftlist: list.uuid })" class="card__anchor">
                    <!--Card Image(s)-->
                    <div class="card__images card__images--multiple">
                        <template v-if="useLazyLoadOnImages">
                            <VLazyImage class="card__image card__images-left" :src="list.image_url[0]" />
                            <div class="card__images-right">
                                <VLazyImage class="card__image image-01" :src="list.image_url[1]" />
                                <VLazyImage class="card__image image-02" :src="list.image_url[2]" />
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="card__image card__images-left"
                                :style="{
                                    backgroundImage:
                                        'url(' + list.image_url[0] + ')',
                                }"
                            ></div>
                            <div class="card__images-right">
                                <div
                                    class="image-01 card__image"
                                    :style="{
                                        backgroundImage:
                                            'url(' + list.image_url[1] + ')',
                                    }"
                                ></div>
                                <div
                                    class="image-02 card__image"
                                    :style="{
                                        backgroundImage:
                                            'url(' + list.image_url[2] + ')',
                                    }"
                                ></div>
                            </div>
                        </template>
                    </div>
                    <!--Card Text-->
                    <div class="card__txt">
                        <div class="card__txt--count">
                            {{ list.item_count }}
                        </div>
                        <div class="card__txt--name">{{ list.name }}</div>
                    </div>
                </Link>
                <!--Card Tools-->
                <footer class="card__tools">
                    <div class="card__tools--icon-label" v-if="list.privacy && list.privacy !== ''">
                        <span class="icon">
                            <component :is="list.privacy === 'public' ? SvgCardPrivacyPublic : SvgCardPrivacyPrivateLink" />
                        </span>
                        <span class="label">{{ privacyLabel }}</span>
                    </div>
                    <!--List Card Option Menu-->
                    <list-card-option-menu :list="props.list" :owner="props.owner"></list-card-option-menu>
                </footer>
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { computed, ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import ListCardOptionMenu from "@/Components/OptionMenus/ListCardOptionMenu.vue";
import SvgCardPrivacyPublic from "~/svg/ui-card-privacy-public.svg";
import SvgCardPrivacyPrivateLink from "~/svg/ui-card-privacy-private-link.svg";
import VLazyImage from 'v-lazy-image';

const cardOptionsMenuStore = useCardOptionsMenuStore();

const props = defineProps({
    list: Object,
    owner: Object,
});

// Props
// const { list, owner } = toRefs(props);

// Local state
const isDeleted = ref(false);
const isHidden = ref(false);
const useLazyLoadOnImages = ref(true);

// Computed properties
const isBookmarksView = computed(() => {
    return route().current() === "bookmarks";
});

const isProcessing = computed(() => {
    return props.list.hasOwnProperty("is_processing")
        ? props.list.is_processing
        : false;
});

const isProcessingText = computed(() => {
    return props.list?.is_processing_text ?? "Processing";
});

const listName = computed(() => {
    return props.list.name.substring(0, 70);
});

const privacyLabel = computed(() => {
    switch (props.list.privacy) {
        case "private":
            return "Private Link";
            break;
        case "public":
            return "Public";
            break;
        default:
            return null;
    }
});

const clickOutside = () => {
    cardOptionsMenuStore.closeAll();
}
</script>
