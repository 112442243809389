<template>
    <div class="loading-animation">
        <Throbber />
        <div class="loading-animation__content" v-if="text">{{ loadingText }}</div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import Throbber from "@/Components/Throbber/Throbber.vue";

const props = defineProps({
    text: {
        type: String,
        default: null
    }
})

const loadingText = computed(() => {
    return typeof props.text !== 'boolean' ? props.text : 'Loading'
});
</script>
