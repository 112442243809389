/**
 * For working with a WishList view.
 * @type Vue/Composable
 */
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useAuthUser } from "@/composables/useAuthUser";
import urlUtilities from "@/utilities/url";

export function useWishList() {
    const user = useAuthUser();
    const giftlist = computed(() => usePage().props.giftlist?.data || null);
    const items = computed(() => usePage().props.items?.data || null);
    const links = computed(() => usePage().props.items?.links || null);
    const meta = computed(() => usePage().props.items?.meta || null);

    // TODO: Need data from @kyle
    // const confirmedGifts = computed(() => {
    //     // return _.filter(this.items, function (item) {
    //     //   return !item.qty_remaining
    //     // })
    //     return this.$store.state.wishlists.confirmedGifts
    // });

    // TODO: Need data from @kyle
    // Will need array of giftlists for item actions, move to, etc.
    const hasLists = computed(() => {
        return true;
        // return !!giftlists.length
    });

    // const hasModalog = computed(() => {
    //     return this.$store.state.modalogs.hasModalog
    // });

    const isExpertList = computed(() => {
        return giftlist.type === 'expert'
    });

    // const isReordering = computed(() => {
    //     return this.$store.state.wishlists.isReordering
    // });

    const isSpoiled = computed(() => {
        return ( giftlist.hasOwnProperty('isSpoiled') && giftlist.isSpoiled )
    });

    // TODO: Do we need?
    // const isThrobbing = computed(() => {
    //     return ( this.isThrobbingApi || this.isThrobbingPagination )
    // });

    // TODO: Do we need?
    // const isThrobbingApi = computed(() => {
    //     return this.$store.state.api.isThrobbing
    // });

    // const isThrobbingPagination = computed(() => {
    //     return this.$store.state.pagination.isThrobbing
    // });

    // const itemCardComponent = computed(() => {
    //     return (this.isThrobbingApi || this.isThrobbingPagination || _.isEmpty(this.list))
    //         ? 'item-card-placeholder'
    //         : 'item-card'
    // });

    // TODO: Confirmed Gifts
    // const items = computed(() => {
    //     if (this.showConfirmedGifts) {
    //         return this.confirmedGifts
    //     } else {
    //         return this.$store.state.wishlists.wishlist.hasOwnProperty('items')
    //             ? this.$store.state.wishlists.wishlist.items
    //             : []
    //     }
    // });

    // TODO: Reordering
    // const itemsMutable = computed(() => {
    //     return this.$store.state.wishlists.itemsMutable
    // });

    // const itemsMutableOrderedBySequence = computed(() => {
    //     return _.orderBy(this.itemsMutable, function (item) {
    //         return item.sequence
    //     }, 'asc')
    // });

    // const list = computed(() => {
    //     return this.$store.state.wishlists.wishlist
    // });

    const queryParamOrderBy = computed(() => {
        const orderBy = urlUtilities.getQuerystringParam('orderBy')
        return orderBy ? orderBy : 'sequence'
    });

    const queryParamPage = computed(() => {
        const page = urlUtilities.getQuerystringParam('page')
        return page ? page : 1
    });

    const queryParamSortDirection = computed(() => {
        const sortDirection =  urlUtilities.getQuerystringParam('sortDirection')
        return sortDirection ? sortDirection : 'asc'
    });

    // TODO: Pinia
    // const showConfirmedGifts = computed(() => {
    //     return this.$store.state.wishlists.showConfirmedGifts
    // });

    const userPreferredCurrency = computed(() => {
        return user.preferred_currency;
    });

    return {
        giftlist,
        items,
        isExpertList,
        links,
        meta,
        queryParamOrderBy,
        queryParamPage,
        queryParamSortDirection,
        userPreferredCurrency,
    }
};
