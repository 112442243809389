<script setup>
import { Link } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import Modalog from "@/Components/Modalogs/Modalog.vue";

const modalogStore = useModalogStore();

const openDeleteAccountConfirmation = () => {
    modalogStore.showModalog({
        type: 'modalog-delete-account-confirmation',
        isConfirmDisabled: false,
    })
}
</script>
<template>
    <modalog content-class="edit-profile" :has-header="true" size="large">
        <template #title>Delete Account</template>
        <template #content>
            <section class="form-section">
                <h3>Before you go&hellip;</h3>
                <p>At Gift Hero our number one priority is making gift giving easy for you. If you're unhappy with any part of the
                    service we welcome your feedback to help us improve. Please email us at
                    <a href="mailto:support@gifthero.com">support@gifthero.com</a>.</p>
                <p>
                    <strong><big>Deleting your Gift Hero account is permanent. If you proceed we cannot recover your account for you.</big></strong>
                </p>
                <p>When you delete your account you will no longer be able to log in and we will remove any connections to social
                    accounts that you may have added. Any gifts that you have confirmed for other users will remain confirmed but your
                    name will not be displayed.<br><br></p>
                <p>
                    <Link :href="route('profile.show')" title="Never mind, I want to keep my account"
                    ><strong>Never mind, I want to keep my account</strong></Link>
                </p>
                <p>If you're having trouble using the site, consider emailing us at <a href="mailto:support@gifthero.com">support@gifthero.com</a>.</p>
                <br>
            </section>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="openDeleteAccountConfirmation">Confirm Account Deletion</button>
        </template>
    </modalog>
</template>
