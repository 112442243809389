<template>
    <modalog :has-header="true" size="medium">
        <template #title>{{ buttonText }}</template>
        <template #content>
            <div class="radio-list__container">
                <template v-if="wishlists?.length">
                    <h2 class="radio-list__header">Your Wish Lists</h2>
                    <ul class="radio-list__list">
                        <li class="radio-list__item" v-for="list in wishlists" :key="list.uuid">
                            <input class="radio-list__input" type="radio" :id="list.uuid" name="giftlist" :value="list.uuid" v-model="giftlistUuid">
                            <label class="radio-list__label" :for="list.uuid">{{ list.name }}</label>
                            <div class="radio-list__checkmark"></div>
                        </li>
                    </ul>
                </template>
                <template class="radio-list__container" v-if="expertlists?.length">
                    <h2 class="radio-list__header">Your Expert Lists</h2>
                    <ul class="radio-list__list">
                        <li class="radio-list__item" v-for="list in expertlists" :key="list.uuid">
                            <input class="radio-list__input" type="radio" :id="list.uuid" name="giftlist" :value="list.uuid" v-model="giftlistUuid">
                            <label class="radio-list__label" :for="list.uuid">{{ list.name }}</label>
                            <div class="radio-list__checkmark"></div>
                        </li>
                    </ul>
                </template>
            </div>
        </template>
        <template #footer>
            <button class="strip-btn dialog__action-button" @click="modalogStore.closeModalog()">Cancel</button>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="actionMethod" :disabled="!giftlistUuid">{{ buttonText }}</button>
        </template>
    </modalog>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import { router, usePage } from "@inertiajs/vue3";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import * as Sentry from "@sentry/vue";
import useEmitter from "@/composables/useEmitter";

const modalogStore = useModalogStore();

const props = defineProps({
    isOwner: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        default: function () { return {} }
    },
    listUuid: {
        type: String,
        default: null
    }
});

const emitter = useEmitter();

// STATE
const errors = ref([]);
const giftlistUuid = ref(null);

// COMPUTED
const buttonText = computed(() => {
    return isMoveAction.value ? `Move to ${listTypeMoniker.value} List` : `Copy to ${listTypeMoniker.value} List`;
});

const expertlists = computed(() => {
    return usePage().props.auth.user?.data?.expertlists.filter((list) => props.listUuid !== list.uuid);
});

const isItemDetail = computed(() => {
    return usePage().component === 'Shared/ItemDetail';
});

const isMoveAction = computed(() => {
    return props.isOwner;
});

const wishlists = computed(() => {
    return usePage().props.auth.user?.data?.wishlists.filter((list) => props.listUuid !== list.uuid);
});

const listTypeMoniker = computed(() => {
    return !selectedWishList.value ? '' : selectedWishList.value?.type.charAt(0).toUpperCase() + selectedWishList.value?.type.substring(1);
});

const selectedWishList = computed(() => {
    return [...expertlists.value, ...wishlists.value].find(function (list) {
        return list.uuid === giftlistUuid.value;
    });
});

// METHODS
const actionMethod = () => {
    modalogStore.isThrobbing = true;
    isMoveAction.value ? moveItemToWishList() : copyItemToWishList();
};

/**
 * Copy the item to another wish list.
 * @note If not list owner.
 */
const copyItemToWishList = () => {
    axios.post(route('item.copy', { item: props.item }), {
        item_uuid: props.item.uuid,
        list_uuid: giftlistUuid.value,
    })
        .then(() => {
            props.item.is_processing = true;
            props.item.is_processing_text = `${props.item.name} Copied to ${selectedWishList.value.name}`;

            setTimeout(() => {
                props.item.is_processing = false;
                props.item.is_processing_text = null;
            }, 3000);
        })
        .catch(error => console.error)
        .finally(() => {
            modalogStore.isThrobbing = false;
            modalogStore.closeModalog();
            emitter.emit('item-copied', { wishlistName: selectedWishList.value.name });
        });
};

/**
 * Move's the item to another wish list.
 * @note If list owner only.
 */
const moveItemToWishList = () => {
    axios.put(route('item.move', { item: props.item }), {
        item_uuid: props.item.uuid,
        list_uuid: giftlistUuid.value,
    })
        .then(() => {
            /* On item detail views, set the wish list to the new wish list. */
            if (isItemDetail.value) {
                router.visit(route('item.detail', { item: props.item }));
            } else {
                props.item.is_processing = true;
                props.item.is_processing_text = `${props.item.name} Moved to ${selectedWishList.value.name}`;

                setTimeout(() => {
                    router.reload({ only: ['items']});
                }, 1000)
            }
        })
        .catch(error => {
            console.error(error);
            Sentry.captureException(error);
            alert('There was an error moving the item. Please try again.');
        })
        .finally(() => {
            modalogStore.isThrobbing = false;
            modalogStore.closeModalog();
        });
};
</script>
