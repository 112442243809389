<script setup>
/**
 * Card option menu shell.
 * @note This component will be the shell for ListCardOptionMenu and ItemCardOptionMenu
 * and will contain shared functionality, such as, show/hide of the card option menu.
 */
import { computed } from "vue";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import IconNavOverflow from '~/svg/ui-nav-overflow.svg';

const cardOptionsStore = useCardOptionsMenuStore();

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    uuid: {
        type: String,
        default: Math.random(),
    },
});

const isVisible = computed({
    get() {
        return props.uuid === cardOptionsStore.openInstance;
    },
    set () {
        cardOptionsStore.updateOpenInstance(props.uuid);
    },
});
</script>
<template>
    <Transition name="fade">
        <div class="card__popover popover" v-if="$page.props.auth.user">
            <input role="button" type="checkbox" class="popover__checkbox card__popover--checkbox" :id="uuid" aria-labelledby="" v-model="isVisible">
            <label class="popover__toggle card__popover--toggle" role="presentation" :for="uuid" :id="uuid" title="Click for actions">
                <span class="icon" aria-hidden="">
                    <icon-nav-overflow alt="Click for actions"></icon-nav-overflow>
                </span>
            </label>
            <div class="popover__content has-animation card__popover--content">
                <div class="popover__options">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>
