<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import dayjs from "dayjs";
import BlogLayout from '@/Layouts/BlogLayout.vue';

const readingTime = computed(() => {
    const htmlString = usePage().props.post.body;
    const words = htmlString.replace(/<[^>]+>/g, '').trim().split(/\s+/).length;
    const wpm = 200; // Average words per minute

    return Math.ceil(words / wpm);
});
</script>
<template>
    <BlogLayout>
        <Head>
            <title>{{ $page.props.post.title }}</title>
            <link rel="canonical" :href="route('blog.show', { slug: $page.props.post.slug })">
            <meta name="title" :content="$page.props.post.seoTitle">
            <meta name="description" :content="$page.props.post.seoDescription">
            <meta property="og:title" :content="$page.props.post.title">
            <meta property="og:url" :content="route('blog.show', { slug: $page.props.post.slug })">
            <meta property="og:image" :content="$page.props.post.seoImageUrl ?? $page.props.post.headerBannerUrl">
            <meta property="og:description" :content="$page.props.post.seoDescription">
            <meta name="twitter:description" :content="$page.props.post.seoDescription">
            <meta name="twitter:image:src" :content="$page.props.post.seoImageUrl">
            <meta property="author" content="Gift Hero">
            <meta property="og:type" content="article">
            <meta name="twitter:card" content="summary_large_image">
            <meta property="article:publisher" content="https://www.facebook.com/gifthero">
            <meta name="robots" v-bind:content="`${ $page.props.post.robotsNoindex ? 'noindex' : 'index' }, ${ $page.props.post.robotsNofollow ? 'nofollow' : 'follow' }`">
            <meta property="article:published_time" :content="$page.props.post.createdAt">
            <meta name="twitter:creator" content="@GiftHero">
            <meta name="twitter:site" content="@gifthero">
            <meta property="og:site_name" content="Gift Hero">
            <meta name="twitter:label1" content="Reading time">
            <meta name="twitter:data1" :content="`${readingTime} min read`">
            <component is="script" type="application/ld+json">
                {
                    "@context": "http://schema.org",
                    "@type": "NewsArticle",
                    "datePublished": "{{ $page.props.post.createdAt }}",
                    "dateModified": "{{ $page.props.post.updatedAt }}",
                    "headline": "{{ $page.props.post.title }}",
                    "name": "{{ $page.props.post.title }}",
                    "keywords": [],
                    "author":
                    {
                        "@type": "Person",
                        "name": "Gift Hero",
                        "url": "{{ route('blog') }}"
                    },
                    "creator": ["Gift Hero"],
                    "publisher":
                    {
                        "@type": "Organization",
                        "name": "Gift Hero",
                        "url": "{{ route('blog') }}"
                    },
                    "mainEntityOfPage": "{{ route('blog.show', { slug: $page.props.post.slug }) }}"
                }
            </component>
        </Head>
        <main class="main has-white-bg" role="main">
            <div class="container-wide stacked-children whitespace-bottom whitespace-top">
                <article class="blog blog-content blog-article">
                    <nav class="blog-breadcrumb whitespace-bottom">
                        <Link :href="route('blog')">Back to Blog</Link>
                    </nav>
                    <time class="blog-datetime" :datetime="$page.props.post.createdAt">{{ dayjs($page.props.post.createdAt).format('MMM D, YYYY') }}</time>
                    <header>
                        <h1>{{ $page.props.post.title }}</h1>
                        <h4 v-if="$page.props.post.subtitle">{{ $page.props.post.subtitle }}</h4>
                    </header>
                    <figure v-if="$page.props.post.headerBannerUrl">
                        <img :src="$page.props.post.headerBannerUrl" :title="$page.props.post.headerBannerTitle">
                    </figure>
                    <section v-html="$page.props.post.body"></section>
                    <div>
                        <hr>
                        <strong>A note on prices:</strong> Any price and availability information displayed on www.amazon.com at the time of purchase will apply to the purchase of the product.
                    </div>
                </article>
            </div>
        </main>
    </BlogLayout>
</template>
