<template>
    <Head :title="`Friends who Follow you on Gift Hero`" />
    <FriendsLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar is-mobile="true" />
        </template>
        <template #content-user-profile-header>
            <UserProfileHeader :user="$page.props.owner ? $page.props.owner.data : $page.props.auth.user.data" />
        </template>
        <template #content>
            <header class="grid-header grid-header--basic">
                <div class="grid-header__title">
                    <h1 v-if="$page.props.owner">{{ $page.props.owner.data.presentation_name }}'s Followers ({{ $page.props.owner.data.followers_count }})</h1>
                    <h1 v-else>Followers <span v-if="$page.props.followers?.data.length"><small>({{ $page.props.followers.data.length }})</small></span></h1>
                </div>
            </header>
            <!--Friends sorting-->
            <div class="grid-header__filter" v-if="!$page.props.owner && sortedFriends.length">
                <div class="select is-transparent has-icon icon-subtle">
                    <i class="icon"><svg-sort></svg-sort></i>
                    <select name="orderBy" v-model="filterType" title="Sort friends by">
                        <option value="recent">Recent</option>
                        <option value="alpha">Alphabetical</option>
                    </select>
                </div>
            </div>
            <div class="hint-panel" v-if="!sortedFriends.length">
                <div class="hint-panel__body">
                    <div class="hint-panel__header">
                        <div class="hint-panel__gh-logo">
                            <GHLogoGift />
                        </div>
                        <div class="hint-panel__title">
                            {{ $page.props.owner ? $page.props.owner.data.full_name : $page.props.auth.user.data.full_name }} doesn't have any followers yet.
                        </div>
                    </div>
                    <!--TODO: Uncomment if we implement Find Friends-->
                    <!--<div class="hint-panel__content">-->
                    <!--    <p>-->
                    <!--        <a href="{{ route('friends.find-friends') }}" class="btn-plain">+ Find Friends</a>-->
                    <!--    </p>-->
                    <!--</div>-->
                </div>
            </div>
            <!--Friends-->
            <div class="list-grid">
                <template v-for="user of sortedFriends">
                    <FriendCard :my-uuid="$page.props.auth.user.data.uuid" :user="user" />
                </template>
            </div>
        </template>
        <template #sidebar-mobile-bottom> </template>
    </FriendsLayout>
</template>
<script setup>
import { computed, ref } from "vue";
import { useFriendsView } from "@/composables/useFriendsView";
import FriendsLayout from "@/Layouts/FriendsLayout.vue";
import Sidebar from "@/Pages/Friends/Partials/Sidebar.vue";
import FriendCard from "@/Components/Cards/FriendCard.vue";
import UserProfileHeader from "@/Components/User/UserProfileHeader.vue";
import SvgSort from '~/svg/ui-sort.svg';
import GHLogoGift from '~/svg/gh-logo-gift.svg'

/////////////////////////////////////////////////////
// Props
/////////////////////////////////////////////////////
const props = defineProps([
    'isOwner',
    'loggedin',
    'myUuid',
    'userUuid',
]);

/////////////////////////////////////////////////////
// State
/////////////////////////////////////////////////////
const dataLoaded = ref(false);
const errorMessage = ref('');
const owner = ref({});
const query = ref('');
const searchQuery= ref('');
const usersCached = ref([]);
const { filterType, friends, isOwner, sortedFriends } = useFriendsView(true);

/* Is this my list of users that I follow? */
const isMine = computed(() => {
    return props.myUuid === props.userUuid;
});
</script>
