<template>
    <AppLayout>
        <SidebarLayout>
            <template #sidebar-widescreen>
                <slot name="sidebar-widescreen" />
            </template>
            <template #content>
                <!-- User Profile Header -->
                <slot name="content-user-profile-header" />
                <slot name="sidebar-mobile-top" />
                <slot name="content"></slot>
            </template>
            <template #sidebar-mobile-bottom>
                <slot name="sidebar-mobile-bottom" />
            </template>
        </SidebarLayout>
    </AppLayout>
</template>
<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SidebarLayout from "@/Layouts/SidebarLayout.vue";

// Options
defineOptions({
    layout: [BaseLayout],
});

/////////////////////////////////////////////////////
// Props
/////////////////////////////////////////////////////
const props = defineProps({
    isOwner: {},
    loggedin: {},
    userUuid: {},
    myUuid: {},
});
</script>
