<template>
    <aside class="error error__block error--warning" v-show="errors.length > 0" :class="classModifier">
        <div class="error-title" :class="{ 'error-title--has-icon': isSingular }">
            <i class="error-title__icon"><svg width="72" height="72" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" v-if="isSingular"><title>icon-error-warning</title><path d="M70.443 51.304L45.813 9.565C43.736 6.132 40.056 4 36 4c-4.058 0-7.739 2.042-9.812 5.565l-24.63 41.74c-2.077 3.523-2.077 7.606 0 11.13C3.635 65.958 7.315 68 11.37 68h49.258c4.06 0 7.74-2.042 9.813-5.565 2.077-3.524 2.077-7.607 0-11.13h.001zm-6.927 6.91c-.278.543-1.3 1.806-3.25 1.806l-48.506.003c-1.95 0-2.881-1.263-3.25-1.805-.373-.542-.931-1.988 0-3.613l24.252-40.637c.93-1.626 2.603-1.805 3.251-1.805.652 0 2.23.18 3.251 1.805l24.253 40.637c.93 1.625.28 3.068 0 3.61zM32 24.689h8v19.687h-8V24.687zm0 23.625h8v7.874h-8v-7.874z" fill-rule="nonzero"/></svg>
            </i>
            <span class="error-title__label">{{ errorHeading }}</span>
        </div>
        <div class="error-extended">
            <p v-if="isSingular">{{ errors[0] }}</p>
            <ul v-else>
                <li v-for="error in errors">
                    {{ error }}
                </li>
            </ul>
        </div>
    </aside>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
    classModifier: {
        type: String,
        default: null,
    },
    customTitle: {
        type: String,
        default: null
    },
    passedErrors: {
        type: Array,
        default: null
    },
    /* Set this prop to `true` if the component should use `api.hasInlineErrors` state, as opposed to the default `api.hasErrors` state. */
    useInlineErrors: {
        type: Boolean,
        default: false,
        required: false,
    }
});

const errors = computed(() => {
    if (props.passedErrors) {
        return props.passedErrors;
    }

    return [];
    // if (props.useInlineErrors) {
    //     return this.$store.state.api.hasInlineErrors !== undefined ? this.$store.state.api.hasInlineErrors : ['Please try your request again.']
    // } else {
    //     return this.$store.state.api.hasErrors !== undefined ? this.$store.state.api.hasErrors : ['Please try your request again.']
    // }
});

const errorHeading =  computed(() => {
    return props.customTitle ? props.customTitle : isSingular.value ? 'An Error Has Occurred' : 'The Following Errors Occurred';
});

const isSingular = computed(() => {
    return (errors.value?.length === 1);
});

// OLD
// export default {
//     created () {
//         /* Allow for passing of errors as a prop to the component, for example in a Blade template. */
//         if (this.passedErrors) {
//             this.$store.commit('api/setErrors', this.passedErrors, { root: true, namespaced: true })
//         }
//     },
// }
</script>
