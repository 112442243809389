<template>
    <section class="masthead-visual masthead-visual--is-wishlist">
        <div class="masthead-visual__primary">
            <div class="masthead-visual__primary--layer-upper">
                <div class="masthead-visual__content">
                    <h1 class="masthead-visual__title">{{ $page.props.giftlist.data.name }}</h1>
                    <div class="masthead-visual__meta">
                        <div class="masthead-visual__meta--badge">
                            <i class="icon">
                                <svg-icon-header-expert-list v-if="isExpertList"></svg-icon-header-expert-list>
                                <svg-icon-header-wish-list v-else></svg-icon-header-wish-list>
                            </i>
                            <span class="text">{{ listTypeMoniker }} List</span>
                        </div>
                        <div class="masthead-visual__meta--badge">
                            <i class="icon">
                                <svg-icon-private-link v-if="isPrivate"></svg-icon-private-link>
                                <svg-icon-public v-else></svg-icon-public>
                            </i>
                            <span class="text">{{ isPrivate ? 'Private Link' : 'Public' }}</span>
                        </div>
                    </div>
                </div>
                <div class="masthead-visual__banner" v-if="$page.props.giftlist.data.event_date">
                    <p>🎉 <span>Event Date</span> <strong>{{ formattedEventDate }}</strong></p>
                </div>
            </div>
            <div class="masthead-visual__primary--layer-lower" :class="{ 'is-default': !listImage }">
            </div>
        </div>
        <div class="masthead-visual__secondary">
            <div class="masthead-visual__description" v-if="$page.props.giftlist.data.description">
                <p>{{ $page.props.giftlist.data.description }}</p>
            </div>
            <ListOptionsMenu :list="$page.props.giftlist.data" :item-count="$page.props.items.data.length" />
        </div>
    </section>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useMastheadImageUrls } from "@/composables/useMastheadImageUrls";
import dayjs from "dayjs";
// TODO: Implement
// import WishlistOptionsMenu from "@/Components/WishList/WishlistOptionsMenu.vue";
import ListOptionsMenu from "@/Components/OptionMenus/ListOptionsMenu.vue";
import SvgIconHeaderExpertList from '~/svg/icon-header-expert-list.svg'
import SvgIconHeaderWishList from '~/svg/icon-header-wish-list.svg'
import SvgIconPrivateLink from '~/svg/icon-privacy-is-private-link.svg'
import SvgIconPublic from '~/svg/icon-privacy-is-public.svg'

const imageUuid = ref(null);

const formattedEventDate = computed(() => {
    return usePage().props.giftlist?.data.event_date ? dayjs(usePage().props.giftlist?.data.event_date).format('MMMM D, YYYY') : null;
});

const isExpertList = computed(() => {
    return usePage().props.giftlist?.data.type === 'expert';
});

const isPrivate = computed(() => {
    return usePage().props.giftlist?.data.privacy === 'private'
});

const listType = computed(() => {
    return usePage().props.giftlist?.data.type;
});

const listTypeMoniker = computed(() => {
    return usePage().props.giftlist?.data.type.charAt(0).toUpperCase() + listType.value.substr(1);
});

const listImage = computed(() => {
    return usePage().props.giftlist?.data.masthead_photo;
});

const mastheadImageUrls = ref(useMastheadImageUrls(listImage.value));

watch(() => usePage().props.giftlist?.data.masthead_photo, (newVal) => {
    mastheadImageUrls.value = useMastheadImageUrls(newVal);
});
</script>
<style scoped>
    @media only screen and (max-width: 37.5rem) {
        .masthead-visual__primary--layer-lower {
            background-image: v-bind('mastheadImageUrls.small1x');
        }
    }

    @media only screen and (max-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (max-width: 37.5rem) and (min-resolution: 192dpi) {
        .masthead-visual__primary--layer-lower {
            background-image: v-bind('mastheadImageUrls.small2x');
        }
    }

    @media only screen and (min-width: 37.5rem) {
        .masthead-visual__primary--layer-lower {
            background-image: v-bind('mastheadImageUrls.large1x');
        }
    }

    @media only screen and (min-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-width: 37.5rem) and (min-resolution: 192dpi) {
        .masthead-visual__primary--layer-lower {
            background-image: v-bind('mastheadImageUrls.large2x');
        }
    }
</style>
