<script setup>
import { Head, Link } from "@inertiajs/vue3";
const IMAGE_BASE_URL = `https://res.cloudinary.com/gift-hero/image/upload/v1685819255/lib/img/mobile-app`
</script>

<template>
    <Head>
        <title>Gift Hero Mobile App for iOS and Android</title>
        <meta name="description" content=""/>
        <meta property="og:title" content=""/>
        <meta property="og:description" content=""/>
        <meta property="og:url" content="{{ route('mobile') }}"/>
        <link rel="canonical" href="{{ route('mobile') }}"/>
        <meta name="apple-itunes-app" content="app-id=1330224345">
    </Head>

    <main class="main has-white-bg has-subtle-gradient" role="main">
        <section class="mobile-app__layout">
            <div class="mobile-app__content">
                <h1 class="title">Adding to Your Wish Lists has Never Been&nbsp;Easier</h1>
                <p>Quickly add anything from your web browser or app to one of your existing wish lists or even create a new one thanks to Gift Hero’s free Add to Wish List app, now available for iOS and Android.</p>
                <div class="links">
                    <a class="link" href="https://itunes.apple.com/us/app/gift-hero/id1330224345?ls=1&mt=8" title="Download for iOS" target="_blank" rel="noopener noreferrer"><img :src="`${IMAGE_BASE_URL}/badge-ios-app-store.svg`" alt="Available on the App Store"></a>
                    <a class="link" href="https://play.google.com/store/apps/details?id=com.giftheroapp" title="Download for Android" target="_blank" rel="noopener noreferrer"><img :src="`${IMAGE_BASE_URL}/badge-google-play.svg`" alt="Get it on Google Play"></a>
                </div>
            </div>
            <div class="mobile-app__visual">
                <picture>
                    <source
                        media="(min-width: 960px)"
                        :srcset="`${IMAGE_BASE_URL}/gift-hero-mobile-app-large_2x.png 2x`" width="516" height="545">
                    <source
                        media="(min-width: 600px)"
                        :srcset="`${IMAGE_BASE_URL}/gift-hero-mobile-app-medium_2x.png 2x`" width="399" height="422">
                    <source
                        media="(min-width: 300px)"
                        :srcset="`${IMAGE_BASE_URL}/gift-hero-mobile-app-small.png,
                ${IMAGE_BASE_URL}/gift-hero-mobile-app-small_2x.png 2x`" width="300" height="316">
                    <img :src="`${IMAGE_BASE_URL}/gift-hero-mobile-app-large.png`" alt="Gift Hero Mobile Apps for iOS and Android">
                </picture>
            </div>
        </section>
    </main>
</template>
