<template>
    <modalog :has-header="false">
        <template #contentTitle>
            <h2 class="dialog__title dialog__title--no-header">Delete {{ listTypeTitleText }} List</h2>
        </template>
        <template #content>
            <p>Are you sure you want to delete <strong>{{ list.name }}</strong> from your wish lists?</p>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--warning" @click.stop="deleteWishList" :disabled="isProcessing">Delete</button>
        </template>
    </modalog>
</template>
<script setup>
import { computed, ref } from "vue";
import { router } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import Modalog from "@/Components/Modalogs/Modalog.vue";

// Pinia Stores
const modalogStore = useModalogStore();

// State
const isProcessing = ref(false);

// Props
const props = defineProps({
    list: {
        type: Object,
        default: {}
    },
    shouldRedirect: {
        type: Boolean,
        default: false
    },
});

// Computed
const listTypeTitleText = computed(() => {
    return props.list.type.charAt(0).toUpperCase() + props.list.type.substr(1);
});

// Methods
/**
 * Delete a wish list.
 */
function deleteWishList () {
    isProcessing.value = true;

    axios.delete(route('giftlist.destroy', { giftlist: props.list.uuid }), )
        .then(() => {
            if (props.shouldRedirect) {
                window.location = route('user.giftlists');
            } else {
                props.list.is_processing = true;
                props.list.is_processing_text = `${props.list.name} Deleted`;
                router.reload({ only: ['expertlists', 'giftlists']});
            }
        })
        .catch(error => {
            console.error(error);
            // TODO: Sentry?
            router.reload({ only: ['expertlists', 'giftlists'] });
        })
        .finally(() => {
            modalogStore.closeModalog();
            isProcessing.value = false;
        });
};
</script>
