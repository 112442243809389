<template>
    <KeepAlive>
        <template v-if="$page.props.auth.user">
            <div class="option-text" v-if="user.following">
                <i class="icon"><svg-green-checkmark></svg-green-checkmark></i>
                <span class="text">Following</span>
            </div>
            <button class="button is-primary is-small is-inverted has-icon" @click="user.following ? unfollowUser() : followUser()" :class="isFollowingButton" :disabled="isSubmitting" v-else>
                <Throbber v-if="isSubmitting" />
                <template v-else>
                    <i class="icon">
                        <svg-follow></svg-follow>
                    </i> {{ buttonStatusText }}
                </template>
            </button>
        </template>
        <template v-else>
            <Link :href="route('login')" class="strip-btn btn-rounded btn-rounded--medium btn-rounded--share" title="Log in to Follow">Log in to Follow</Link>
        </template>
    </KeepAlive>
</template>
<script setup>
import { computed, ref } from "vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import { useApiService } from "@/services/useApiService";
import { useIsOwner } from "@/composables/useIsOwner";
import SvgFollow from "~/svg/ui-option-follow.svg";
import SvgGreenCheckmark from "~/svg/icon-checkmark-green.svg";
import Throbber from "@/Components/Throbber/Throbber.vue";

// Props
const props = defineProps({
   user: {
       type: Object,
       default: {},
       required: true,
   }
});

// Services
const apiService = useApiService();

// State
const { isOwner } = useIsOwner();
const isSubmitting = ref(false);

// Computed
const owner = computed(() => usePage().props.owner?.data);

const buttonStatusText = computed(() => {
    return owner.following ? 'Unfollow' : 'Follow';
});

// const isMyFollowersView = computed(() => {
//     return this.$store.state.view.current === 'following-mine'
// });

const isFollowingButton = computed(() => {
    return owner && owner.following ? 'btn-rounded--subdued' : null;
});

//----- Methods
function followUser () {
    isSubmitting.value = true;

    apiService.followUser(props.user.uuid)
        .then(() => {
            if (usePage().component === 'Friends/FindFriends') {
                props.user.following = true;
            } else {
                router.reload();
            }
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            isSubmitting.value = false;
        });
};

// TODO
// function unfollowUser () {
//     console.log('Unfollow user');
//     // this.isSubmitting = true
//     // this.$store.dispatch('showModalog', {
//     //     type: 'modalog-unfollow-confirmation',
//     //     props: {
//     //         user: this.user
//     //     },
//     //     isConfirmDisabled: false
//     // }).then(response => {
//     //     this.isSubmitting = false
//     // })
// };
</script>
