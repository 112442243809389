/**
 * State for Modalogs.
 */

import { defineStore } from "pinia";
import { ref } from 'vue';
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";

export const useModalogStore = defineStore('modalog', () => {
    const hasFooter = ref(true); // Whether the fixed footer should be displayed
    const hasHeader = ref(false); // Whether the fixed header should be displayed
    const hasModalog = ref(false); // Show/hides the modalog
    const hasInlineModalog = ref(false); // Show/hides the inline modalog
    const isConfirmDisabled = ref(false); // Set the state of the confirmation button
    const isThrobbing = ref(false);
    const modalogProps = ref({}); // Dynamic props for modalogs
    const modalogType = ref(null); // The type of modal to be displayed, see `/_js/vue/modalogs/` for types.
    const shareData = ref({}); // The data needed to populate the share modalog
    const size = ref('small'); // The size of the modal, (small, medium, large)
    const title = ref(''); // The title content

    const cardOptionsMenuStore = useCardOptionsMenuStore();

    function closeModalog() {
        hasModalog.value = false;
        isThrobbing.value = false;
    };

    function setModalogOptions(payload) {
        for (let key in payload) {
            if (!payload.hasOwnProperty(key)) {
                continue;
            }

            [key].value = payload[key];
        }
    };

    function setShareData(payload) {
        shareData.value = payload;
    };

    function showInlineModalog({ commit }) {
        hasInlineModalog.value = true;
    };

    /**
     * Show a modalog
     * @param commit
     * @param type
     */
    // showModalog ({ commit, rootState }, payload) {
    function showModalog(payload) {
        // Show modalog
        hasModalog.value = true;
        // Hide other primary nav elements
        // console.log(rootState)
        // rootState.notificationsPanelIsVisible = false
        // rootState.siteNavIsVisible = false
        // rootState.userMenuIsVisible = false

        /* Handle other edge cases. */
        if (payload.hasOwnProperty('type')) {
            modalogType.value = payload.type;
        }
        if (payload.hasOwnProperty('props')) {
            modalogProps.value = payload.props;
        }
        if (payload.hasOwnProperty('isConfirmDisabled')) {
            isConfirmDisabled.value = payload.isConfirmDisabled;
        }
        if (payload.hasOwnProperty('options')) {
            setModalogOptions(payload.options);
        }

        // Close all card options menus
        cardOptionsMenuStore.closeAll();
    }

    return {
        closeModalog,
        hasFooter,
        hasHeader,
        hasInlineModalog,
        hasModalog,
        isConfirmDisabled,
        isThrobbing,
        modalogProps,
        modalogType,
        setShareData,
        shareData,
        showInlineModalog,
        showModalog,
        size,
        title,
    }
});
