<template>
    <WishListLayout>
        <template #list-grid-start>
            <!--Add Item-->
            <AddItemCard :list="$page.props.giftlist?.data" v-if="!$page.props?.isConfirmed" />
            <!--Onboarding Card-->
            <template v-if="giftlist.items?.length < 3">
                <WishListOnboardingCard />
            </template>
        </template>
        <template #confirmed-gifts v-if="$page.props?.isConfirmed">
        </template>
        <template #list-grids-end>
            <!--List Spoiler Button-->
            <div class="list-actions__post" v-if="!isExpertList">
                <header>
                    <h2>
                        <template v-if="!isSpoiled">Want to see which gifts have been confirmed?</template>
                        <template v-else>Stop showing confirmed gifts for this wish list</template>
                    </h2>
                </header>
                <list-spoiler-button :is-standalone="true" :list="giftlist"></list-spoiler-button>
            </div>
        </template>
    </WishListLayout>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { useAuthUser } from "@/composables/useAuthUser";
import { useModalogStore } from "@/stores/modalog";
import { useWishList } from '@/composables/useWishList';
import { useWishlistStore } from "@/stores/wishlist";
import AddItemCard from "@/Components/Cards/Item/AddItemCard.vue";
import ListSpoilerButton from "@/Components/Actions/ListSpoilerButton.vue";
import WishListLayout from "@/Layouts/WishListLayout.vue";
import WishListOnboardingCard from "@/Components/Cards/WishListOnboardingCard.vue";

const modalogStore = useModalogStore();
const wishlistStore = useWishlistStore();
const { giftlist, isExpertList } = useWishList();
const user =  useAuthUser();

const isSpoiled = computed(() => {
    return (route().current() === 'list.confirmed') || giftlist.value?.isSpoiled;
});

onMounted(() => {
    const currentRoute = route().current();

    switch (currentRoute) {
        case 'list.reorder':
            modalogStore.showModalog({
                type: 'ModalogWishListItemsReorder',
                props: {
                    hasCloseX: false,
                    itemsPerPage: 15,
                    listUuid: giftlist.value?.uuid || null,
                }
            });
            break;
        case 'list.confirmed':
            if (!wishlistStore.isSpoiled) {
                modalogStore.showModalog({
                    type: 'modalog-list-spoiler-confirmation',
                    props: {
                        list: giftlist.value
                    },
                    isConfirmDisabled: false
                });
            }
            break;
        default:
            wishlistStore.isSpoiled = false;
            modalogStore.closeModalog();
            break;
    }
});
</script>
