<template>
    <Transition name="dialog">
        <div class="dialog__body dialog__body--inline">
            <button class="btn btn-link dialog__close" @click="cancel">
                <svg-x></svg-x>
            </button>
            <header class="dialog__header dialog__header--tight">
                <h2 class="dialog__title">{{ titleText }}</h2>
            </header>
            <div class="dialog__content dialog__content--no-pad-t-b">
                <div class="content-errors" v-if="apiErrors">
                    <errors :use-inline-errors="true"></errors>
                </div>
                <p><a rel="noopener noreferrer" target="_blank" href="https://support.gifthero.com/page/adding-gifts/">Learn about Default Currency</a></p>
            </div>
            <footer class="dialog__footer dialog__footer--no-border dialog__footer--no-shadow">
                <Throbber v-if="form.processing"></Throbber>
                <template v-else>
                    <template v-if="!isUpdated">
                        <button class="button is-white" @click="cancel">No</button>
                        <button class="button is-white" @click="updatePreferences">Yes</button>
                    </template>
                </template>
            </footer>
        </div>
    </Transition>
</template>
<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { router, useForm } from "@inertiajs/vue3";
// TODO
// import Errors from '../utilities/errors';
import SvgX from '~/svg/ui-x-skinny.svg';
import Throbber from '@/utilities/Throbber.vue';
// Stores
import { useModalogStore } from "@/stores/modalog";

const modalogStore = useModalogStore();

// Props
const props = defineProps({
    currencyCode: {
        type: String,
        default: null,
        required: true
    },
    currencySymbol: {
        type: String,
        default: null,
        required: true
    }
});

// State
const isUpdated = ref(false);

// Hooks
const form = useForm({
    preferred_currency: props.currencyCode,
});

watch(() => props.currencyCode, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        form.preferred_currency = newValue;
    }
});

onMounted(() => {
    // On initial load, don't show the default currency inline modalog.
    // The inline modalog can be displayed when setting the initial state of the currency select dropdown
    // based on the user's preferred currency.
    nextTick(() => {
        modalogStore.hasInlineModalog = false;
   });
});

// Computed
const apiErrors = computed(() => {
    return false;

    // TODO: Refactor below to Pinia? Or just inline in thi component?
    // if (this.$store.state.api.hasInlineErrors.length) {
    //     return this.$store.state.api.hasInlineErrors;
    // } else {
    //     return false;
    // }
});

const titleText = computed(() => {
    return isUpdated.value
        ? `${props.currencySymbol}${props.currencyCode} is saved as your default currency.`
        : `Set your default currency to ${props.currencySymbol}${props.currencyCode}?`
});

// Methods
function cancel() {
    modalogStore.hasInlineModalog = false;
    // TODO
    // this.$store.commit('api/setInlineErrors', []);
    // Reset the component to initial state when closing.
    nextTick(() => {
        isUpdated.value = false;
    });
};

// TODO: Handle errors
function updatePreferences() {
    console.log('Updating preferences');

    form.post(route('preferences.update'), {
        onSuccess: () => {
            isUpdated.value = true;
            setTimeout(() => {
                modalogStore.hasInlineModalog = false;
            }, 3000);
            router.reload({ 'only': ['auth.user'] } );
        },
        onError: (errors) => {
            console.log('Error updating preferences');
            console.log(errors);
            modalogStore.hasInlineModalog = false;
        }
    });
};
</script>
