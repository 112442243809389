<template>
    <Link class="nav-back__profile" :href="route('user.giftlists', { username: owner.username })" :title="owner.presentation_name">
        <i class="nav-back__profile--icon"><SvgBack /></i>
        <figure class="nav-back__profile--image">
            <img :src="owner.avatar['profile_photo']" :alt="owner.presentation_name">
        </figure>
        <span class="nav-back__profile--label">{{ backText }}</span>
    </Link>
</template>
<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import SvgBack from '~/svg/icon-angled-bracket-left.svg'

const owner = usePage().props.owner?.data;

const backText = computed(() => {
    return `${owner.presentation_name}’s Wish\u00a0Lists`;
});
</script>
