/**
 * Only allow numbers and a single period. If there is a period, it must be followed by another number.
 * Valid: 1 / 1.2 / 0.1
 * Invalid 1,000 / 1. / .1
 * @url https://stackoverflow.com/questions/5570820/regex-allow-digits-and-a-single-dot?answertab=votes#tab-top
 */
const priceInputs = new RegExp(/^(\d+)(\.\d+)?(?=$| )/);

export default {
    priceInputs
}
