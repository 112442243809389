<script setup>
import { inject } from "vue";
import { router } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";

const route = inject('route')
const modalogStore = useModalogStore();

const props = defineProps({
    list: {
        type: Object,
        required: true,
    },
});

const cancel = () => {
    modalogStore.closeModalog();
}

const removeWishListImage = () => {
    modalogStore.isThrobbing = true;

    axios.delete(route('giftlists.delete_masthead_photo', { giftlist: props.list.uuid }))
        .then( () => {
            modalogStore.closeModalog();
            router.reload();
        })
        .finally(() => {
            modalogStore.isThrobbing = false;
        })
    ;
}
</script>
<template>
    <modalog :has-header="true">
        <template #title>Remove Current Header Photo?</template>
        <template #content>
            <p>Your header photo makes your user profile more personal. Do you really want to remove it?</p>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary is-danger" @click="removeWishListImage">Remove Photo</button>
        </template>
    </modalog>
</template>
