<script setup>
import { inject, onBeforeUpdate, onMounted } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import { useImageUpload } from "@/composables/useImageUpload";
import { useModalogStore } from "@/stores/modalog";
import * as Sentry from "@sentry/vue";

const route = inject('route');
const CLOUDINARY_UPLOAD_PRESET = 'user_profile_photo';
const { cancel, dropzoneRef, fetchUploadSignature, file, form, imageAdded, imageUploaded, resetDropzone, uploadImage } = useImageUpload();
const modalogStore = useModalogStore();

const user = usePage().props?.auth?.user?.data ?? null;

onMounted(async () => {
    await fetchUploadSignature(CLOUDINARY_UPLOAD_PRESET);
});

onBeforeUpdate(async () => {
    await fetchUploadSignature(CLOUDINARY_UPLOAD_PRESET);
});

const saveProfilePhoto = () => {
    form.post(route('profile.photo.store'), {
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: () => {
            modalogStore.closeModalog();
            resetDropzone();
            router.reload({
                force: true,
                forceFormData: true,
                replace: true,
            });
        },
    })
}

const uploadAndSave = async () => {
    modalogStore.isThrobbing = true;

    try {
        uploadImage()
            .then((responseData) => {
                modalogStore.isThrobbing = false;
                form.imageUuid = responseData.asset_id;
                form.asset_id = responseData.asset_id;
                form.public_id = responseData.public_id;
                saveProfilePhoto();
            });
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
}
</script>
<template>
    <modalog confirm-button-text="Save" :has-header="true">
        <template #title>{{ user?.avatar?.profile_photo ? 'Change Profile Photo' : 'Upload Profile Photo' }}</template>
        <template #content>
            <div class="margin-bottom-small margin-top-small">
                <image-upload ref="dropzoneRef" @image-added="imageAdded" @image-uploaded="imageUploaded"></image-upload>
            </div>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="uploadAndSave" :disabled="!file">Save</button>
        </template>
    </modalog>
</template>
