/**
 * useAuthUser composable
 * @returns {object} $page.props.auth.user.data from Inertia.js
 */
import { computed } from "vue";
import { usePage } from '@inertiajs/vue3';

export function useAuthUser() {
    const user = computed(() => usePage().props.auth.user?.data || null);

    return user;
}
