<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import Header from './Partials/Header.vue';
import Footer from './Partials/Footer.vue';

defineProps({
    title: String,
});

const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
    router.put(route('current-team.update'), {
        team_id: team.id,
    }, {
        preserveState: false,
    });
};

const logout = () => {
    router.post(route('logout'));
};
</script>

<template>
    <Header />
    <slot />
    <Footer />
</template>
