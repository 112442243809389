<template>
    <section class="sidebar-nav__container">
        <nav class="sidebar-nav sidebar-nav__limited">
            <ul class="sidebar-nav__list--level-2">
                <li class="sidebar-nav__item--level-2" :class="{ 'is-active': route().current() === 'user.giftlists' }">
                    <Link :href="route('user.giftlists', { username: $page.props.auth.user.data.username })" title="Mine">Mine</Link>
                </li>
                <li class="sidebar-nav__item--level-2" :class="{ 'is-active': route().current() === 'bookmarks' }">
                    <Link :href="route('bookmarks')" title="Bookmarks">Bookmarks</Link>
                </li>
            </ul>
        </nav>
    </section>
</template>
