/**
 * Custom Vuelidate Validation rules.
 */
import { helpers } from '@vuelidate/validators';

/**
 * Instagram username validation.
 * @type {boolean|*}
 */
const instagramUsername = (value) => !helpers.req(value) || /^(\@)?([a-z0-9_.]{1,30})$/i.test(value);

/**
 * Twitter username validation.
 * @param value
 * @return {boolean}
 */
const twitterUsername = (value) => !helpers.req(value) || /^(\@)?([A-Za-z0-9_]{1,15})$/i.test(value);

/**
 * Emulates Laravel's `alpha_dash` validation rule.
 * @note The field under validation may have alpha-numeric characters, as well as dashes and underscores.
 * @param value
 * @return {boolean}
 */
const alphaDash = (value) => !helpers.req(value) || /^[A-Za-z0-9\-\_]{1,35}$/i.test(value);

// export
export { alphaDash, instagramUsername, twitterUsername }
