<script setup>
import { Head, Link } from "@inertiajs/vue3";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
const browser_info = usePage().props.browser_info;
const {
    browser,
    is_mobile,
    is_supported,
    is_tablet,
    supported_extensions,
    store_urls,
} = browser_info;

const otherBrowsers = computed(() => {
    const browsersToShow = { ...supported_extensions };
    if (!is_mobile && !is_tablet) {
        delete browsersToShow[browser];
    }

    return browsersToShow;
});
const IMAGE_BASE_URL = `https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/browsers`;
</script>

<template>
    <Head>
        <title>Browser Extensions</title>
        <meta
            name="description"
            content="The Gift Hero browser extension makes it easy to save to your wish lists."
        />
        <meta
            property="og:title"
            content="Download the Gift Hero browser extension"
        />
        <meta
            property="og:description"
            content="The Gift Hero browser extension makes it easy to save to your wish lists."
        />
        <meta property="og:url" :content="route('browser-extensions')" />
        <link rel="canonical" :href="route('browser-extensions')" />
    </Head>

    <main class="main has-white-bg" role="main">
        <section class="browser-extension">
            <div class="container-wide stacked-children">
                <header class="browser-extension__header">
                    <h1>
                        <span>The Gift Hero </span>
                        <span>Browser Extension</span>
                    </h1>
                    <h2>The fastest way to add gifts to your wish lists.</h2>
                </header>
                <div class="browser-extension__install">
                    <!--                    Desktop-->
                    <!--                    If the browser is one of our supported extension, show the install CTA. Otherwise, show nothing.-->

                    <div
                        v-if="!is_mobile && !is_tablet && is_supported"
                        class="browser-extension__module--desktop"
                    >
                        <img
                            :src="`${IMAGE_BASE_URL}/browser-icon-large-${browser.toLowerCase()}.png`"
                            :srcset="`${IMAGE_BASE_URL}/browser-icon-large-${browser.toLowerCase()}_2x.png 2x, ${IMAGE_BASE_URL}/browser-icon-large-${browser.toLowerCase()}.png 1x`"
                            class="-icon"
                            :alt="`${browser}`"
                        />
                        <div class="-cta">
                            <h2>
                                It looks like you are using
                                <strong>{{ browser }}</strong>
                            </h2>
                            <a
                                :href="`${store_urls[browser]}`"
                                class="btn-solid-rounded-full"
                                :title="`Install ${supported_extensions[browser]} Extension`"
                                target="_blank"
                                rel="“noopener”"
                                >Install
                                {{ supported_extensions[browser] }}
                                Extension</a
                            >
                        </div>
                    </div>
                    <!--                    Mobile/Tablet-->
                    <div
                        class="browser-extension__module browser-extension__module--mobile"
                        v-if="is_mobile || is_tablet"
                    >
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/browser-extension/phone-sms-large.svg"
                            class="-img"
                            alt="Add products by sending a text."
                        />
                        <div class="-body">
                            <header class="-header">
                                <h3>Using a mobile phone?</h3>
                                <h2>Download our app for iOS and Android</h2>
                            </header>
                            <div class="-cta">
                                <Link
                                    href="/mobile"
                                    class="btn-strip"
                                    title="See How"
                                    >See Apps &rarr;</Link
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="browser-extension__module--desktop"
                        v-if="!is_supported"
                    >
                        <p>
                            Sorry, we don't have an Extension for your browser
                            yet. The extension is available for these browsers:
                        </p>
                    </div>
                </div>
                <!--                Callout-->
                <div class="browser-extension__callout--desktop">
                    <!--                    Intro text, platform dependant-->

                    <h4 v-if="is_mobile || is_tablet">
                        <em>Also available on your computer for:</em>
                    </h4>

                    <h4 v-if="!is_mobile && !is_tablet">
                        <em>Also available for:</em>
                    </h4>

                    <!--                    List of supported browser extensions-->

                    <ul
                        class="browser-extension__supported-browsers"
                        v-if="supported_extensions"
                    >
                        <li
                            v-for="(extension, key) in otherBrowsers"
                            :key="extension"
                        >
                            <a
                                :href="`${store_urls[key]}`"
                                :title="`Also available for ${extension}`"
                                target="_blank"
                                rel="“noopener”"
                            >
                                <img
                                    :src="`${IMAGE_BASE_URL}/browser-icon-small-${key.toLowerCase()}.png`"
                                    :srcset="`${IMAGE_BASE_URL}/browser-icon-small-${key.toLowerCase()}_2x.png 2x, ${IMAGE_BASE_URL}/browser-icon-small-${extension.toLowerCase()}.png 1x`"
                                    :alt="extension"
                                />
                                <p>{{ extension }}</p>
                            </a>
                        </li>
                    </ul>
                </div>

                <!--                Learn More-->
                <section
                    class="browser-extension__learn-more browser-extension__learn-more--mobile"
                >
                    <div
                        class="browser-extension__learn-more__col browser-extension__content"
                    >
                        <header class="-header">
                            <h2>
                                Save products to your Gift Hero wish lists from
                                any site without leaving the page.
                            </h2>
                        </header>
                        <div class="-body"></div>

                        <a
                            :href="`${store_urls[browser]}`"
                            class="btn-alt -cta"
                            :title="`Install ${supported_extensions[browser]} Extension`"
                            target="_blank"
                            rel="“noopener”"
                            v-if="!is_mobile && !is_tablet && is_supported"
                            >Install
                            {{ supported_extensions[browser] }}
                            Extension</a
                        >
                    </div>
                    <div
                        class="browser-extension__learn-more__col browser-extension__video"
                    >
                        <iframe
                            width="1280"
                            height="720"
                            src="https://www.youtube.com/embed/Wt7H_WQgB0o?rel=0&amp;controls=1"
                            allowfullscreen
                        ></iframe>
                    </div>
                </section>
            </div>
        </section>
    </main>
</template>
