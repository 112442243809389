<template>
    <div class="throbber">
        <div class="throbber__dots throbber__dots--small">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    </div>
</template>
