import { defineStore } from 'pinia';
import { ref } from "vue";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useModalogStore } from "@/stores/modalog";

export const useRootStore = defineStore('root', () => {
    const { closeAll } = useCardOptionsMenuStore();
    const { closeModalog } = useModalogStore();

    const hasModal = ref(false);
    const isLoggedIn = ref(false);
    const isMobile = ref(window.hasOwnProperty('vuedata') ? window.vuedata.isMobile : null);
    const modernizr = ref({});
    const notificationsPanelIsVisible = ref(false);
    const siteNavIsVisible = ref(false);
    const token = ref(window.hasOwnProperty('vuedata') ? window.vuedata.csrf : null);
    const user = ref(null);
    const userMenuIsVisible = ref(false);

    function closeUserMenu () {
        userMenuIsVisible.value = false;
    };
    function toggleSiteNav () {
        this.siteNavIsVisible = !this.siteNavIsVisible;
    };
    function toggleUserMenu () {
        userMenuIsVisible.value = !userMenuIsVisible.value;
        notificationsPanelIsVisible.value = false;
        siteNavIsVisible.value = false;
        closeAll();
        closeModalog();
    };

    return {
        closeUserMenu,
        hasModal,
        isLoggedIn,
        isMobile,
        modernizr,
        notificationsPanelIsVisible,
        siteNavIsVisible,
        toggleUserMenu,
        toggleSiteNav,
        token,
        user,
        userMenuIsVisible,
    }
});
