<template>
    <AppLayout>
        <SidebarLayout>
            <template #sidebar-widescreen>
                <slot name="sidebar-widescreen" />
            </template>
            <template #sidebar-mobile-top>
                <slot name="sidebar-mobile-top" />
            </template>
            <template #content>
                <!-- User Profile Header -->
                <slot name="content-user-profile-header" />
                <slot name="content"></slot>
            </template>
            <template #sidebar-mobile-bottom>
                <slot name="sidebar-mobile-bottom" />
            </template>
        </SidebarLayout>
    </AppLayout>
</template>
<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SidebarLayout from "@/Layouts/SidebarLayout.vue";

defineOptions({
    layout: [BaseLayout],
});
</script>
