<template>
    <currency-select :currency-code="props.currencyCode" @currency-code-selected="emitCurrencyCodeSelected"></currency-select>
</template>
<script setup>
import CurrencySelect from "@/Components/forms/CurrencySelect.vue";
import { usePage } from "@inertiajs/vue3";

// Props
const props = defineProps({
    currencyCode: {
        type: String,
        default: usePage().props?.auth.user.data.preferred_currency ?? 'USD',
        required: false,
    }
});

// Emits
const emits = defineEmits(['currency-code-selected']);

// Methods
function emitCurrencyCodeSelected(currencyCode) {
    emits('currency-code-selected', currencyCode);
}
</script>
