<template>
    <button class="popover__option strip-btn is-danger" @click="openDeleteItemModalog">
        <span class="icon"><icon-option-delete></icon-option-delete></span>
        <span class="label">Delete Item</span>
    </button>
</template>
<script setup>
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useModalogStore } from "@/stores/modalog";
import IconOptionDelete from '~/svg/ui-option-delete.svg'

// Stores
const cardOptionsMenuStore = useCardOptionsMenuStore();
const modalogStore = useModalogStore();

// Props
const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    redirectUrl: {
        type: String,
        default: null
    }
});

function openDeleteItemModalog () {
    // Dispatch modalog
    modalogStore.showModalog({
        type: 'ModalogDeleteItem',
        props: { item: props.item, redirectUrl: props.redirectUrl }
    });

    // Hide card options menus
    cardOptionsMenuStore.closeAll();
}
</script>
