<template>
        <div class="masthead-visual__list-options" v-if="authUser" :style="{ display: 'inline-flex' }" v-click-outside="closeListOptionsMenu">
            <div>
                <input role="button" type="checkbox" class="popover__checkbox" id="list-options" aria-labelledby="list-options" v-model="isVisible">
                <label class="button is-primary is-small is-inverted has-icon" role="presentation" for="list-options" id="list-options" aria-hidden="true"><i class="icon"><icon-option-cog/></i> List Options</label>
                <div class="masthead-visual__list-options--menu popover__content has-animation has-animation--top-center">
                    <div class="popover__options">
                        <template v-if="isListOwner">
                            <!--Edit Wish List-->
                            <button class="popover__option strip-btn" @click="showEditWishListModalog">
                                <span class="icon"><icon-option-edit/></span>
                                <span class="label">Edit {{ listTypeMoniker }} List</span>
                            </button>
                            <!--Organize Gifts-->
                            <button class="popover__option strip-btn" @click.stop="redirectToReorder" :disabled="isReorderButtonDisabled" v-if="props.itemCount >= 2">
                                <span class="icon"><icon-organize/></span>
                                <span class="label">Reorganize Gifts</span>
                            </button>
                            <!--Upload/Change Header Photo-->
                            <button class="popover__option strip-btn" @click.stop="showMastheadImageUploadModalog">
                                <span class="icon"><icon-image-upload/></span>
                                <span class="label">{{ list.masthead_photo ? 'Change Header Photo' : 'Upload Header Photo' }}</span>
                            </button>
                            <hr class="popover__option--divider">
                            <CopyLink :copy-url="list.url" />
                        </template>
                        <template v-else-if="!isListOwner">
                            <CopyLink :copy-url="list.url" />
                            <BookmarkWishList :list="list" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { router } from "@inertiajs/vue3";
import { useAuthUser } from "@/composables/useAuthUser";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useModalogStore } from "@/stores/modalog";
import BookmarkWishList from "@/Components/Actions/BookmarkWishList.vue";
import CopyLink from "@/Components/Actions/CopyLink.vue";
import IconOptionEdit from '~/svg/ui-option-edit.svg';
import IconOptionCog from '~/svg/ui-option-cog.svg';
import IconOrganize from '~/svg/ui-organize.svg';
import IconImageUpload from '~/svg/ui-image-upload.svg';

const authUser = useAuthUser();
const cardOptionsStore = useCardOptionsMenuStore();
const modalogStore = useModalogStore();

const props = defineProps({
    list: {
        type: Object,
        default: function () { return {} }
    },
    itemCount: {
        type: Number,
        default: 0
    },
});

// const isVisible = ref(false);
const isVisible = computed({
    get() {
        return props.list.uuid === cardOptionsStore.openInstance;
    },
    set () {
        cardOptionsStore.updateOpenInstance(props.list.uuid);
    },
});
const isReorderButtonDisabled = ref(false);

const isExpertList = computed(() => {
    return props.list.type === 'expert';
});

const isListOwner = computed(() => {
    return props.list.is_owner;
});

const listTypeMoniker = computed(() => {
    return props.list.type.charAt(0).toUpperCase() + props.list.type.substring(1);
});

const closeListOptionsMenu = () => {
    cardOptionsStore.closeAll();
}

const redirectToReorder = () => {
    isReorderButtonDisabled.value = true;
    router.get(route('list.reorder', { giftlist: props.list.uuid }));
}

/**
 * Show the edit wish list modalog.
 */
const showEditWishListModalog = () => {
    modalogStore.showModalog({
        type: 'ModalogCreateEditWishList',
        props: {
            isEdit: true,
            list: props.list,
            listType: props.list.type,
        },
        isConfirmDisabled: false
    });
}

/**
 * Show the masthead image upload modalog.
 */
const showMastheadImageUploadModalog = () => {
    modalogStore.showModalog({
        type: 'ModalogMastheadImageUpload',
        props: {
            list: props.list
        },
        isConfirmDisabled: true
    });
}
</script>
