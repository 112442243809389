<script setup>
import { router, usePage } from "@inertiajs/vue3";
import { useModalogStore} from "@/stores/modalog";

const modalogStore = useModalogStore();

const props = defineProps({
    content: {
        type: String,
        required: true,
    },
    imageUuid: {
        type: String,
        default: null,
        required: false,
    },
    showModalogOnClose: {
        type: String,
        default: null,
        required: false,
    },
    showModalogOnCloseProps: {
        type: Object,
        default: null,
        required: false,
    },
    title: {
        type: String,
        required: true,
    },
    routeForDelete: {
        type: String,
        required: true,
    },
})

const list = usePage().props?.giftlist?.data ?? null;

const cancel = () => {
    // Because this modalog is opened from another modalog,
    // we need to check if we need to show another modalog on close.
    if (props.showModalogOnClose) {
        modalogStore.showModalog({
            type: props.showModalogOnClose,
            props: props.showModalogOnCloseProps,
            isConfirmDisabled: true
        });
    } else {
        modalogStore.closeModalog();
    }
};

const removeHeaderPhoto = () => {
    modalogStore.isThrobbing = true;

    axios.delete(props.routeForDelete)
        .then(() => {
            modalogStore.closeModalog();
            router.reload();
        })
        .finally(() => modalogStore.isThrobbing = false)
    ;
};
</script>
<template>
    <modalog :has-header="true">
        <template #title>{{ props.title }}</template>
        <template #content>
            <p>{{ props.content }}</p>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
        </template>
        <template #confirmButton>
            <button class="strip-btn dialog__action-button dialog__action-button--primary is-danger" @click="removeHeaderPhoto">Remove Photo</button>
        </template>
    </modalog>
</template>
