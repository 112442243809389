<script setup>
import { ref, computed } from 'vue'
import { chunk, cloneDeep, isEmpty, isObjectLike } from "lodash";
import Draggable from 'vuedraggable';
import ItemCardSimple from "@/Components/Cards/Item/ItemCardSimple.vue";
import Throbbing from "@/Components/Throbber/Throbbing.vue";
import { useModalogStore } from "@/stores/modalog";
import { router, usePage } from "@inertiajs/vue3";

const modalogStore = useModalogStore();

const props = defineProps({
    itemsPerPage: {
        type: Number,
        default: 15,
        required: false,
    },
    listUuid: {
        type: String,
        required: true,
    }
});

const isSaveDisabled = ref(true);
const isThrobbing = ref(false);
const items = ref(usePage().props.items?.data || []);
const itemsMutable = ref(cloneDeep(items.value));
const list = ref(usePage().props.giftlist?.data || {});
const reorderedItems = ref({});
const sortableJsOptions = {
    dataIdAttr: 'data-uuid',
    delay: 0,
    draggable: '.card-simple',
    filter: '.page-grid',
    forceFallback: false,
    scroll: true,
    scrollSensitivity: 150,
    scrollSpeed: 50,
    touchStartThreshold: 3,
};

const chunkedItems = computed(() => chunk(itemsMutable.value, props.itemsPerPage));
const hasModalog = computed(() => store.state.modalogs.hasModalog);

const cancel = () => {
    router.get(route('list.show', { giftlist: props.listUuid }), {}, {
        preserveState: false,
        replace: true
    });
};

const checkForReorderedItems = () => {
    isSaveDisabled.value = !!isEmpty(reorderedItems);
};

// START Sortable JS methods
// @todo Should the sequence logic be abstracted elsewhere for reuse?
const sortableCalculateNewSequence = (payload, item) => {
    const newIndex = payload.newIndex; // Item's index after being dropped.
    const itemLeft = itemsMutable.value[newIndex - 1];
    const itemLeftSequence = isObjectLike(itemLeft) ? itemLeft.sequence : null;
    const itemRight = itemsMutable.value[newIndex + 1];
    const itemRightSequence = isObjectLike(itemRight) ? itemRight.sequence : null;

    // The new item sequence
    let newItemSequence = item.sequence;

    if (itemLeftSequence && itemRightSequence) { // Item moved between two items
        newItemSequence = (itemLeft.sequence + itemRight.sequence) / 2;
    } else if (itemLeftSequence) { // Item moved to end
        newItemSequence = itemLeftSequence - 1000;
    } else if (itemRightSequence) { // Item moved to beginning
        newItemSequence = itemRightSequence + 1000;
    }

    return newItemSequence;
}

const sortableJsEnd = (payload) => {
    console.log(payload);
    const newIndex = payload.newIndex;
    const item = itemsMutable.value[newIndex]; // Reference to the item object.
    const newItemSequence = sortableCalculateNewSequence(payload, item);
    console.log(`New item sequence is ${newItemSequence}`);
    reorderedItems.value[item.uuid] = newItemSequence;
    item.sequence = newItemSequence; // Update the sequence on the mutable item.
    checkForReorderedItems();
};

const sortableSave = () => {
    isSaveDisabled.value = true;

    axios.put(route('item.reorder'), { items: reorderedItems.value })
        .then(() => {
            router.get(route('list.show', { giftlist: props.listUuid }), {}, {
                preserveState: false,
                replace: true
            });
        })
        .catch((error) => {
            console.log(error);
            Sentry.captureException(error);
        })
        .finally(() => {
            isSaveDisabled.value = false;
        })
    ;
};
</script>
<template>
    <modalog dialog-class="dialog--fullscreen" :has-close-x="true" :has-header="false" :should-footer-throb="false" size="fullscreen">
        <template #content>
            <throbbing text="Loading" v-if="isThrobbing"></throbbing>
            <draggable
                class="list-grid list-grid-reordering"
                :options="sortableJsOptions"
                @end="sortableJsEnd"
                v-model="itemsMutable"
                v-else
                item-key="uuid"
            >
                <template #item="{element}">
                    <item-card-simple :item="element" :key="element.uuid"></item-card-simple>
                </template>
                <template #footer>
                    <!--Page divider lines-->
                    <div class="page-grid">
                        <span class="page-divider" v-for="i of chunkedItems" :key="i"></span>
                    </div>
                </template>
            </draggable>
        </template>
        <template #footer>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="sortableSave" :disabled="isSaveDisabled || isThrobbing">Save</button>
        </template>
    </modalog>
</template>
