<template>
    <!--Sidebar layout-->
    <div class="sidebar-layout">
        <!--Sidebar-->
        <div class="sidebar-layout__sidebar">
            <div class="sidebar">
                <section class="sidebar-nav__container">
                    <slot name="sidebar-widescreen" />
                </section>
            </div>
        </div>
        <!--Main-->
        <div class="sidebar-layout__main">
            <slot name="sidebar-mobile-top" />
            <slot name="content" />
            <slot name="sidebar-mobile-bottom" />
        </div>
    </div>
</template>
