<template>
    <Modalog :has-header="true" :has-inline-errors="true" size="medium">
        <template #title>{{ modalogTitle }}</template>
        <template #content>
            <!--Errors @todo MR: Needs new error styling. This is the 3.x markup-->
            <div class="hint-panel is-error arrow-bottom-left" v-if="form.hasErrors">
                <div class="hint-panel__body">
                    <div class="hint-panel__header">
                        <div class="hint-panel__title">
                            Could not {{ isEdit ? 'update' : 'create new' }} list.
                        </div>
                    </div>
                    <div class="hint-panel__content" v-for="error in Object.values(form.errors)">
                        <p>{{ error }}</p>
                    </div>
                </div>
            </div>
            <!--/End old errors-->
            <!--Wish List Name-->
            <div class="dialog__content--row">
                <label class="form-label" for="wishlist-name">{{ listMoniker }} List Name</label>
                <input class="input" type="text" name="list-name" :placeholder="listNameRandom" v-model="form.name" ref="wishlistNameInput">
            </div>
            <!--List Type-->
            <div class="dialog__content--row" v-if="!isEdit">
                <label for="list-type" class="form-label">List Type</label>
                <div class="select">
                    <select name="list-type" id="list-type" v-model="form.type">
                        <option value="wish" v-if="!profileUser?.is_gift_hero">Wish List</option>
                        <option value="expert">Expert List</option>
                    </select>
                </div>
            </div>
            <!--Wish List Privacy-->
            <div class="dialog__content--row">
                <!--Wish List Privacy-->
                <div class="form-control">
                    <h2 class="form-label">Who Can See this {{ listMoniker }} List?</h2>
                    <div class="form-control__button-option form-control__button-option--stacked">
                        <label class="form-control--radio-button">
                            <input type="radio" name="list-visibility" value="public" v-model="form.privacy">
                            <div class="control__indicator control__indicator--equal control__indicator--privacy-public">
                                <i class="icon"><icon-public/></i><span class="label">Public</span>
                            </div>
                        </label>
                        <span class="control__description">Anyone online</span>
                    </div>
                    <div class="form-control__button-option form-control__button-option--stacked">
                        <label class="form-control--radio-button">
                            <input type="radio" name="list-visibility" value="private" v-model="form.privacy">
                            <div class="control__indicator control__indicator--equal control__indicator--privacy-private-link">
                                <i class="icon"><icon-private-link/></i><span class="label">Private Link</span>
                            </div>
                        </label>
                        <span class="control__description">Only those with a link to {{ listMoniker }} list</span>
                    </div>
                </div>
            </div>
            <template v-if="isEdit">
                <!--Wish List Event Data - Edit Only-->
                <div class="dialog__content--row">
                    <label class="form-label is-optional" for="event-date">Event Date</label>
                    <datepicker :sel-day="eventDateDay" :sel-month="eventDateMonth" :sel-year="eventDateYear" @datecleared="dateCleared" @dateupdated="dateUpdated" ref="datepicker" />
                    <span class="help">Is this {{ listMoniker }} List for a specific date?</span>
                </div>
                <!--Wish List Description-->
                <div class="dialog__content--row">
                    <label class="form-label" for="list-description">Description</label>
                    <textarea class="textarea" name="list-description" id="list-description" placeholder="A list of gifts for…" rows="2" v-model="form.description">{{ descriptionAutoGrow }}</textarea>
                </div>
            </template>
            <privacy-hint></privacy-hint>
        </template>
        <template #footer>
            <button class="strip-btn dialog__action-button" @click="cancel">Cancel</button>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="updateWishList" :disabled="!listDataValid" v-if="props.isEdit">Save Changes</button>
            <button class="strip-btn dialog__action-button dialog__action-button--primary" @click="createWishList" :disabled="!listDataValid || form.processing" v-if="!props.isEdit">{{ createWishlistText }}</button>
        </template>
    </Modalog>
</template>
<script setup>
import {computed, onMounted, ref, watch} from "vue";
import { router } from '@inertiajs/vue3';
import Datepicker from '@/Components/Datepicker.vue';
import IconPrivateLink from '~/svg/icon-privacy-is-private-link.svg'
import IconPublic from '~/svg/icon-privacy-is-public.svg'
import PrivacyHint from "@/Components/hints/PrivacyHint.vue";
import * as Sentry from '@sentry/vue';
// import Throbber from '../utilities/throbber.vue'
import { useAPIForm } from "@/composables/useAPIForm";
import { useModalogStore } from "@/stores/modalog";

const props = defineProps({
    /* The action we'll take, either 'create' or 'edit' */
    isEdit: {
        type: Boolean,
        default: false,
        required: true,
    },
    list: {
        type: Object,
        default: function () { return {} }
    },
    listType: {
        type: String,
        default: 'wish',
        required: false,
        validation: function (value) {
            return ['expert', 'wish'].includes(value)
        }
    }
});

// change to useTemplateRef after we get the new Vue version
// https://vuejs.org/guide/essentials/template-refs
const cachedList = ref({});
const datepicker = ref(null);
const wishlistNameInput = ref(null);
const descriptionAutoGrow = ref('');
const isEventDateValid = ref(true);

const form = useAPIForm({
    description: props.isEdit ? props.list.description : '',
    name: props.isEdit ? props.list.name : '',
    privacy: props.isEdit ? props.list.privacy : 'public',
    type: props.isEdit ? props.list.type : 'wish',
    event_date: props.isEdit ? props.list.event_date : null,
});

const modalogStore = useModalogStore();

const createWishlistText = computed(() => {
    return (form.processing) ? 'Creating' : `+ Create ${listMoniker.value} List`
});

const dateSegments = computed(() => {
    return props.list.event_date ? props.list.event_date.split('-') : ['','',''];
});

const eventDateYear = computed(() => {
    return dateSegments.value[0];
});

const eventDateMonth = computed(() => {
    return dateSegments.value[1];
});

const eventDateDay = computed(() => {
    return dateSegments.value[2];
});

const isExpertList = computed(() => {
    return form.type === 'expert';
});

const listEventDate = computed(() => {
    return props.list.event_date;
});

const listMoniker = computed(() => {
    return isExpertList.value ? 'Expert' : 'Wish';
});

const listNameRandom = computed(() => {
    let thisYear = new Date().getFullYear();
    let data =  [
        'Birthday ' + thisYear,
        'All I want for Christmas is...',
        'Our wedding day',
        'Ben\'s 5th Birthday'
    ];

    return data[Math.floor(Math.random() * data.length)];
});

const listDataValid = computed(() => {
    return !!(form.name.length && !form.processing && isEventDateValid.value);
});

const modalogTitle = computed(() => {
    if (props.isEdit) {
        return isExpertList.value ? `Edit ${listMoniker.value} List` : `Edit ${listMoniker.value} List`;
    } else {
        return isExpertList.value ? `Create ${listMoniker.value} List` : `Create ${listMoniker.value} List`;
    }
});

function cancel () {
    modalogStore.closeModalog();
    form.reset();
    form.clearErrors();

    if (props.isEdit) {
        datepicker.value.resetSelectedValues();
    }
};

function createWishList () {
    form.post(route('giftlist.store'), {
        preserveScroll: true,
        onBefore: () => { modalogStore.isThrobbing = true; },
        onFinish: () => { modalogStore.isThrobbing = false; },
        onSuccess: (data) => {
            form.reset();
            modalogStore.closeModalog();
            // Refetches the giftlist data if the user navigates back to the page.
            router.reload({
                only: ["giftlists", "expertlists"],
                onFinish: () => {
                    router.get(`/list/${data.uuid}`);
                }
            });
        },
        onError: (response) => {
            console.error(response);
            Sentry.captureException(response);
        }
    });
};

function dateCleared () {
    form.event_date = null;
    isEventDateValid.value = true;
};

function dateUpdated (data) {
    if (data.valid) {
        form.event_date = data.date;
        isEventDateValid.value = true;
    } else {
        form.event_date = null;
        isEventDateValid.value = false;
    }
};

function resetFormValues () {
    if (props.isEdit && props.list) {
        ['description', 'event_date', 'name', 'privacy', 'type'].forEach(key => {
            form[key] = props.list[key] ?? form[key];
        });
    } else {
        form.description = '';
        form.name = '';
        form.privacy = 'public';
        form.type = props.listType ?? 'wish';
        form.event_date = null;
    }
}

function updateWishList () {
    form.put(route('giftlist.update', { giftlist: props.list.uuid }), {
        onBefore: () => { modalogStore.isThrobbing = true; },
        onFinish: () => { modalogStore.isThrobbing = false; },
        onSuccess: () => {
            modalogStore.closeModalog();
            router.reload({ only: ['giftlist'] });
        },
        onError: (response) => {
            Sentry.captureException(response);
        }
    })
}

watch(
    () => modalogStore.hasModalog,
    (newHasModalog) => {
        if (newHasModalog) resetFormValues();
    }
)

onMounted(() => {
    resetFormValues();
    wishlistNameInput.value.focus();
});
</script>
