<script setup>
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import CopyLink from "@/Components/Actions/CopyLink.vue";
import DeleteItem from "@/Components/Actions/DeleteItem.vue";
import IconNavOverflow from '../../../svg/ui-nav-overflow.svg'
import MoveCopyItemToWishList from "@/Components/Actions/MoveCopyItemToWishList.vue";
import MoveItemToNewWishList from "@/Components/Actions/MoveItemToNewWishList.vue";

const props = defineProps({
    giftlist: {
        type: Object,
        default: {}
    },
    item: {
        type: Object,
        default: {}
    },
    isOwner: {
        type: Boolean,
        default: false
    }
});

const isVisible = ref(false);

const isLoggedIn = computed(() => {
    return usePage().props.auth.user;
});

function closeItemOptionsMenu () {
    isVisible ? isVisible.value = false : true;
}
</script>
<template>
    <div class="item-detail__header--options" v-click-outside="closeItemOptionsMenu">
        <input role="button" type="checkbox" id="item-options" aria-labelledby="item-options" class="popover__checkbox grid-header__options--checkbox" v-model="isVisible">
        <label role="presentation" for="item-options" id="item-options" aria-hidden="true" class="popover__toggle grid-header__options--toggle">
            <icon-nav-overflow />
        </label>
        <div class="popover__content has-animation grid-header__options--menu">
            <div class="popover__options">
                <template v-if="isLoggedIn">
                    <move-copy-item-to-wish-list :item="item" :is-owner="isOwner" />
                    <hr class="popover__option--divider">
                </template>
                <copy-link :copy-url="route('item.detail', { item })" />
                <template v-if="isLoggedIn && isOwner">
                    <hr class="popover__option--divider">
                    <delete-item :item="item" v-if="isOwner"></delete-item>
                </template>
            </div>
        </div>
    </div>
</template>
