<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const emit = defineEmits(['dateupdated', 'datecleared']);

const props = defineProps(['selDay', 'selMonth', 'selYear']);

const monthsArray = [
    {
        val: '01',
        text: 'Jan'
    },
    {
        val: '02',
        text: 'Feb'
    },
    {
        val: '03',
        text: 'Mar'
    },
    {
        val: '04',
        text: 'Apr'
    },
    {
        val: '05',
        text: 'May'
    },
    {
        val: '06',
        text: 'Jun'
    },
    {
        val: '07',
        text: 'Jul'
    },
    {
        val: '08',
        text: 'Aug'
    },
    {
        val: '09',
        text: 'Sep'
    },
    {
        val: '10',
        text: 'Oct'
    },
    {
        val: '11',
        text: 'Nov'
    },
    {
        val: '12',
        text: 'Dec'
    },
];
const selectedMonth = ref(props.selMonth || '');
const selectedDay = ref(props.selDay || '');
const selectedYear = ref(props.selYear || '');

onMounted(() => {
    dateChanged();
});

const clearDate = () => {
    selectedMonth.value = '';
    selectedDay.value = '';
    selectedYear.value = '';
    emit('datecleared', null);
};

const days = () => {
    let data = [];
    let count = 31;
    const shortMonths = ['04', '06', '09', '11'];
    const isLeapYear = ((selectedYear.value % 4 == 0) && (selectedYear.value % 100 != 0)) || (selectedYear.value % 400 == 0);


    if (selectedMonth.value.length) {
        if (selectedMonth.value === '02')  {
            count = isLeapYear ? 29 : 28;
        } else if (shortMonths.includes(selectedMonth.value)) {
            count = 30;
        }
    }

    for (let i = 1; i <= count; i++)  {
        i < 10 ? data.push('0' + i) : data.push(i);
    }

    return data;
};

const months = () => monthsArray;

const years = () => {
    let data = [];
    let currentYear = new Date().getFullYear();
    let startYear = (!isNaN(Number(selectedYear.value)) && parseInt(selectedYear.value) < currentYear) ? parseInt(selectedYear.value) : currentYear;

    for (let i = startYear; i <= currentYear + 25; i++)  {
        data.push(i);
    }

    return data;
};

const dateChanged = () => {
    isDayValid();

    const eventDate = {
        date: isDateEmpty.value ? '' : selectedDateFormatted.value,
        valid: isDateValid.value,
    }

    emit('dateupdated', eventDate);
};

const isDateValid = computed(() => {
    if (isDateEmpty.value) return true;

    if (
        selectedYear.value > 2000 &&
        selectedMonth.value.toString().length &&
        selectedDay.value.toString().length
    ) {
        const dayjsValidation = dayjs(
            selectedDateFormatted.value,
            'YYYY-MM-DD',
            true
        ).isValid();

        return dayjsValidation;
    } else {
        return false;
    }
});

const isDateEmpty = computed(() => {
    return (
        isEmpty(selectedYear.value)
        && isEmpty(selectedMonth.value)
        && isEmpty(selectedDay.value)
    );
});

const isDayValid = () => {
    if (!isNaN(Number(selectedDay.value)) && parseInt(selectedDay.value) > days().length) {
        selectedDay.value = '';
    }
};

const resetSelectedValues = () => {
    selectedMonth.value = props.selMonth || '';
    selectedDay.value = props.selDay || ''
    selectedYear.value = props.selYear || '';
}

const selectedDateFormatted = computed(() => {
    return `${selectedYear.value}-${selectedMonth.value}-${selectedDay.value}`
});

watch(props.selDay, (newDay) => {
    selectedDay.value = newDay;
});

watch(props.selMonth, (newMonth) => {
    selectedMonth.value = newMonth;
});

watch(props.selYear, (newYear) => {
    selectedYear.value = newYear;
});

defineExpose({
    resetSelectedValues,
});
</script>
<template>
    <div class="control">
        <div class="control">
            <!--Month-->
            <div class="select mr-1">
                <select name="list-month" id="list-month" v-model='selectedMonth' @change="dateChanged">
                    <option value="">Month</option>
                    <option :value="month.val" v-for="month of months()">{{ month.text }}</option>
                </select>
            </div>
            <!--Day-->
            <div class="select mr-1">
                <select name="list-day" id="list-day" v-model="selectedDay" @change="dateChanged()">
                    <option value="">Day</option>
                    <option :value="day" v-for="day of days()">{{ day }}</option>
                </select>
            </div>
            <!--Year-->
            <div class="select mr-1">
                <select name="list-year" id="list-year" v-model="selectedYear" @change="dateChanged()">
                    <option value="">Year</option>
                    <option :value="year" v-for="year of years()">{{ year }}</option>
                </select>
            </div>
            <!--Clear date-->
            <a href="javascript:void(0)" class="button is-primary is-rounded is-teensy is-inverted has-shadow" @click.stop="clearDate" v-if="!isDateEmpty">Clear Date</a>
        </div>
    </div>
</template>

