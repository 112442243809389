<script setup>
import {ref} from 'vue';
import {Link, router, useForm} from '@inertiajs/vue3';
import ActionMessage from '@/Components/ActionMessage.vue';
import FormSection from '@/Components/FormSection.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import Checkbox from "@/Components/Checkbox.vue";

const props = defineProps({
    user: Object,
});

const form = useForm({
    _method: 'PUT',
    first_name: props.user.data.first_name,
    last_name: props.user.data.last_name,
    email: props.user.data.email,
    photo: null,
    username: props.user.data.username,
    preferred_currency: props.user.data.preferred_currency,
    exclude_from_search: props.user.data.exclude_from_search,
    organization_name: props.user.data.organization_name,
    email_new_follower: props.user.data.email_new_follower === 1,
    email_birthdays: props.user.data.email_birthdays === 1,
    email_events: props.user.data.email_events === 1,
    email_marketing: props.user.data.email_marketing === 1,
});

const verificationLinkSent = ref(null);
const photoPreview = ref(null);
const photoInput = ref(null);

const updateProfileInformation = () => {
    if (photoInput.value) {
        form.photo = photoInput.value.files[0];
    }

    form.post(route('user-profile-information.update'), {
        errorBag: 'updateProfileInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
    });
};

const sendEmailVerification = () => {
    verificationLinkSent.value = true;
};

const selectNewPhoto = () => {
    photoInput.value.click();
};

const updatePhotoPreview = () => {
    const photo = photoInput.value.files[0];

    if (! photo) return;

    const reader = new FileReader();

    reader.onload = (e) => {
        photoPreview.value = e.target.result;
    };

    reader.readAsDataURL(photo);
};

const deletePhoto = () => {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null;
            clearPhotoFileInput();
        },
    });
};

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};
</script>

<template>
    <FormSection @submitted="updateProfileInformation">
        <template #title>
            Profile Information
        </template>

        <template #description>
            Update your account's profile information and email address.
        </template>

        <template #form>
            <!-- Profile Photo -->
            <div v-if="$page.props.jetstream.managesProfilePhotos" class="col-span-6 sm:col-span-4">
                <!-- Profile Photo File Input -->
                <input
                    ref="photoInput"
                    type="file"
                    class="hidden"
                    @change="updatePhotoPreview"
                >

                <InputLabel for="photo" value="Photo" />

                <!-- Current Profile Photo -->
                <div v-show="! photoPreview" class="mt-2">
                    <img :src="user.data.profile_photo_url" :alt="user.data.first_name" class="rounded-full h-20 w-20 object-cover">
                </div>

                <!-- New Profile Photo Preview -->
                <div v-show="photoPreview" class="mt-2">
                    <span
                        class="block rounded-full w-20 h-20 bg-cover bg-no-repeat bg-center"
                        :style="'background-image: url(\'' + photoPreview + '\');'"
                    />
                </div>

                <SecondaryButton class="mt-2 mr-2" type="button" @click.prevent="selectNewPhoto">
                    Select A New Photo
                </SecondaryButton>

                <SecondaryButton
                    v-if="user.data.profile_photo_path"
                    type="button"
                    class="mt-2"
                    @click.prevent="deletePhoto"
                >
                    Remove Photo
                </SecondaryButton>

                <InputError :message="form.errors.photo" class="mt-2" />
            </div>

            <!-- First Name -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="first_name" value="First Name" />
                <TextInput
                    id="first_name"
                    v-model="form.first_name"
                    type="text"
                    class="mt-1 block w-full"
                    autocomplete="given-name"
                />
                <InputError :message="form.errors.first_name" class="mt-2" />
            </div>

            <!-- Last Name -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="last_name" value="Last Name" />
                <TextInput
                    id="last_name"
                    v-model="form.last_name"
                    type="text"
                    class="mt-1 block w-full"
                    autocomplete="family-name"
                />
                <InputError :message="form.errors.last_name" class="mt-2" />
            </div>

            <!-- Username -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="username" value="Username" />
                <TextInput
                    id="username"
                    v-model="form.username"
                    type="text"
                    class="mt-1 block w-full"
                    maxlength="35"
                    pattern="^[a-z0-9_\-]+$"
                    title="Use only letters, numbers, dashes, and underscores. No spaces!"
                />
                <InputError :message="form.errors.username" class="mt-2" />
                <p class="block font-medium text-sm text-gray-700">Your profile page will be: <br /> https://www.gifthero.com/{{ user.data.username}}</p>
            </div>

            <!-- Email -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="email" value="Email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                />
                <InputError :message="form.errors.email" class="mt-2" />

                <div v-if="$page.props.jetstream.hasEmailVerification && user.data.email_verified_at === null">
                    <p class="text-sm mt-2">
                        Your email address is unverified.

                        <Link
                            :href="route('verification.send')"
                            method="post"
                            as="button"
                            class="underline text-gray-600 hover:text-gray-900"
                            @click.prevent="sendEmailVerification"
                        >
                            Click here to re-send the verification email.
                        </Link>
                    </p>

                    <div v-show="verificationLinkSent" class="mt-2 font-medium text-sm text-green-600">
                        A new verification link has been sent to your email address.
                    </div>
                </div>
            </div>

            <!-- Preferred Currency -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="preferred_currency" value="Preferred Currency"/>
                <select v-model="form.preferred_currency" id="preferred_currency" name="preferred_currency" class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm">
                    <option :value="code" v-for="(symbol, code) of $page.props.currencies">{{ code }}: {{ symbol }}</option>
                </select>
                <InputError :message="form.errors.preferred_currency" class="mt-2"/>
            </div>

            <!-- Exclude From Search -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="exclude_from_search" value="Exclude From Search"/>

                <select v-model="form.exclude_from_search" id="exclude-from-search" name="exclude_from_search" class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm">
                    <option value="0">Yes, include me in Find Friends</option>
                    <option value="1">Don't include me in Find Friends</option>
                </select>

                <div class="mt-2 font-medium text-sm text-gray-600">
                    Users can <a href="/friends/find-friends">search</a> for other users by first and last name, username, or organization name.
                    <a href="https://support.gifthero.com/page/sharing-and-connecting/#how-do-i-find-someone-or-an-organization-on-gift-hero">Learn More</a>
                </div>

                <InputError :message="form.errors.exclude_from_search" class="mt-2"/>
            </div>

            <!-- Organization Name -->
            <div class="col-span-6 sm:col-span-4">
                <InputLabel for="organization_name" value="Organization Name"/>
                <TextInput
                    id="organization_name"
                    v-model="form.organization_name"
                    type="text"
                    class="mt-1 block w-full"
                />
                <div class="mt-2 font-medium text-sm text-gray-600">
                    Visible to all users. Contact Support to get your organization verified.
                </div>

                <InputError :message="form.errors.organization_name" class="mt-2"/>
            </div>

            <!-- Email Preferences -->
            <div class="col-span-6 sm:col-span-4">
                <h3 class="text-lg font-medium text-gray-900 mb-2">Email Preferences</h3>
                <div class="flex items-center mb-2">
                    <Checkbox id="email_new_follower" class="mr-1" v-model:checked="form.email_new_follower" name="email_new_follower" />
                    <InputLabel for="email_new_follower" value="Email me when someone follows me"/>
                </div>
                <div class="flex items-center mb-2">
                    <Checkbox id="email_birthdays" class="mr-1" v-model:checked="form.email_birthdays" name="email_birthdays" />
                    <InputLabel for="email_birthdays" value="Email me a reminder about upcoming birthdays"/>
                </div>
                <div class="flex items-center mb-2">
                    <Checkbox id="email_events" class="mr-1" v-model:checked="form.email_events" name="email_events" />
                    <InputLabel for="email_events" value="Email me a reminder about upcoming events"/>
                </div>
                <div class="flex items-center mb-2">
                    <Checkbox id="email_marketing" class="mr-1" v-model:checked="form.email_marketing" name="email_marketing" />
                    <InputLabel for="email_marketing" value="Subscribe to marketing emails"/>
                </div>
                <InputError :message="form.errors.email_new_follower" class="mt-2"/>
                <InputError :message="form.errors.email_birthdays" class="mt-2"/>
                <InputError :message="form.errors.email_events" class="mt-2"/>
                <InputError :message="form.errors.email_marketing" class="mt-2"/>
            </div>


        </template>

        <template #actions>
            <ActionMessage :on="form.recentlySuccessful" class="mr-3">
                Saved.
            </ActionMessage>

            <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Save
            </PrimaryButton>
        </template>
    </FormSection>
</template>
