<script setup>
import { onClickOutside } from "@vueuse/core";
import { computed, ref, onMounted } from "vue";
const IMAGE_BASE_URL = `https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/img/about/headshots/`;
const el = ref(null);
const isVisible = ref(false);

const props = defineProps({
    baseUrl: {
        type: String,
        default: "",
    },
    firstName: {
        type: String,
        default: "",
    },
    lastName: {
        type: String,
        default: "",
    },
    bio: {
        type: Object,
        default: {},
    },
});

onMounted(() => {
    if (window.location.hash === `#${fullName.value}`) {
        setActiveComponent();
    }
});

onClickOutside(el, (event) => {
    isVisible.value = false;
    handlePushState();
});

const fullName = computed(() => {
    return `${props.firstName.toLowerCase()}-${props.lastName.toLowerCase()}`;
});

const classes = computed(() => {
    return {
        "is-active": isVisible.value,
        "is-inactive": !isVisible.value,
    };
});

const handlePushState = () => {
    if (isVisible.value) {
        scrollToEl();

        window.history.pushState({}, "", `#${fullName.value}`);
    } else {
        window.history.pushState({}, "", "/about-us");
    }
};

const hide = () => {
    isVisible.value = false;
    handlePushState();
};
const setActiveComponent = () => {
    isVisible.value = true;
    scrollToEl(true);
};
const scrollToEl = (delay = false) => {
    const scroll = () => {
        console.log("scrolling");
        el.value.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
    };
    if (delay) {
        // a slightly delay here ensures the page always scrolls to this element after loading.
        setTimeout(scroll, 100);
    } else {
        scroll();
    }
};
const toggleVisibility = () => {
    isVisible.value = !isVisible.value;
    handlePushState();
};
</script>

<template>
    <article
        class="layout-about__bios--card"
        :class="classes"
        :id="fullName"
        ref="el"
    >
        <a
            class="link"
            :href="`/about-us#${fullName}`"
            @click.prevent="toggleVisibility"
        >
            <figure class="profile">
                <img
                    class="headshot"
                    :src="`${IMAGE_BASE_URL}${bio.headshot}`"
                    :srcset="`${IMAGE_BASE_URL}${bio.headshot} 1x, ${IMAGE_BASE_URL}${bio.headshot2x} 2x`"
                    :alt="`${firstName} { $lastName }`"
                    height="124"
                    width="124"
                />
            </figure>
            <h2 class="name">{{ firstName }} {{ lastName }}</h2>
            <h3 class="title">{{ bio.title }}</h3>
            <span class="more">About {{ firstName }}</span>
        </a>
        <transition name="fade">
            <div class="bio">
                <i class="card-close" @click.stop="hide"
                    ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M7.619 6l4.046 4.046a1.144 1.144 0 1 1-1.619 1.619L6 7.619l-4.046 4.046a1.144 1.144 0 1 1-1.619-1.619L4.381 6 .335 1.954A1.144 1.144 0 1 1 1.954.335L6 4.381 10.046.335a1.144 1.144 0 1 1 1.619 1.619L7.619 6z"
                        /></svg
                ></i>
                <div class="content">
                    <div v-html="bio.bio"></div>

                    <p v-if="bio.linkedIn">
                        <a
                            class="social-link"
                            :href="bio.linkedIn"
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i class="icon"
                                ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <g fill="none" fill-rule="evenodd">
                                        <path
                                            fill="#0077B5"
                                            d="M22.222 0H1.772C.794 0 0 .775 0 1.73v20.538C0 23.224.794 24 1.772 24h20.45c.98 0 1.778-.776 1.778-1.732V1.73C24 .775 23.201 0 22.222 0z"
                                        ></path>
                                        <path
                                            fill="#FFF"
                                            d="M20.45 20.451h-3.556v-5.57c0-1.328-.026-3.036-1.85-3.036-1.852 0-2.135 1.447-2.135 2.94v5.666H9.35V8.998h3.415v1.565h.049c.475-.9 1.637-1.85 3.369-1.85 3.602 0 4.267 2.371 4.267 5.456v6.282zM5.337 7.432a2.063 2.063 0 1 1 0-4.127 2.063 2.063 0 0 1 0 4.127zm-1.78 13.02V8.997h3.56v11.453h-3.56z"
                                        ></path>
                                    </g></svg></i
                            >LinkedIn Profile</a
                        >
                    </p>
                </div>
            </div>
        </transition>
    </article>
</template>
