<script setup>
import { computed } from "vue";

const props = defineProps({ status: Number });

const title = computed(() => {
    return {
        503: "We’ll be right back!",
        500: "Internal Server Error: 500",
        410: "Error: 410",
        405: "Method Not Allowed Error: 405",
        404: "Error: 404",
        403: "Error: 403",
    }[props.status];
});

const description = computed(() => {
    return {
        503: "Sorry for the interuption. Gift Hero is undergoing temporary maintenance. We will be back up again soon!",
        500: "Golly! It looks like our server has encountered an error.",
        410: "Whoops! Looks like the page you are requesting does not exist.",
        405: "Sorry partner, it looks like that’s not allowed.",
        404: "Whoops! Looks like the page you are requesting does not exist.",
        403: "Sorry, not allowed.",
    }[props.status];
});

const showHelpText = computed(() => {
    return `${props.status[0]}` === "4";
});
</script>

<template>
    <Head>
        <title>{{ title }}</title>
    </Head>
    <main class="main has-white-bg" role="main">
        <div
            class="container-wide stacked-children whitespace-bottom whitespace-top"
        >
            <div data-layout="module" data-layout-options="gutters wrap-items">
                <div
                    data-layout="module__item"
                    data-layout-width="one-whole c-one-quarter"
                >
                    <div class="copy-block"></div>
                </div>
                <div
                    data-layout="module__item"
                    data-layout-width="one-whole c-one-half"
                >
                    <div class="copy-block">
                        <h1>{{ title }}</h1>
                        <p>{{ description }}</p>
                        <div v-if="showHelpText">
                            <p>
                                Please check the page address and try again. You
                                can also:
                            </p>
                            <ul>
                                <li>
                                    <a href="https://www.gifthero.com"
                                        >Return to the Gift Hero home page</a
                                    >
                                </li>
                                <li>
                                    <a
                                        href="https://www.gifthero.com/contact-us"
                                        >Contact us for more help</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
