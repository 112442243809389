<template>
    <Head :title="wishlistName" >
        <meta name="description" :content="pageDescription" />
        <meta property="og:title" :content="ogTitle" />
        <meta property="og:description" :content="ogDescription" />
        <meta property="og:url" :content="ogUrl" />
        <meta property="og:image" :content="ogImage"/>
        <link rel="canonical" :href="ogUrl" />
        <meta v-if="noIndex" name="robots" content="noindex, follow"/>
    </Head>
    <AppLayout>
        <SidebarLayout>
            <template #sidebar-widescreen>
                <BackToWishLists />
                <slot name="sidebar"></slot>
            </template>
            <template #content>
                <!-- MastheadVisual-->
                <MastheadVisual />
                <!-- Sorting-->
                <!--TODO: Extract to SFC-->
                <!--List Controls-->
                <div class="grid-header__filter">
                    <!--List sorting-->
                    <!--<template v-if="showSortFilters">-->
                    <!--    <div class="select is-transparent has-icon icon-subtle">-->
                    <!--        <i class="icon"><svg-sort></svg-sort></i>-->
                    <!--        <select name="orderBy" v-model="orderBy" @change="toggleSort" title="Sort gifts by">-->
                    <!--            <option value="sequence">Default</option>-->
                    <!--            <option value="price">Price: Low → High</option>-->
                    <!--        </select>-->
                    <!--    </div>-->
                    <!--</template>-->
                </div>
                <section class="list-grids">
                    <template v-if="route().current() === 'list.confirmed' && wishlistStore.isSpoiled">
                        <div class="list-reveal">
                            <header class="list-reveal__header">
                                <h2 class="list-reveal__title"><strong>{{ $page.props.totalConfirmed  }}</strong> Confirmed Gifts</h2>
                                <list-spoiler-button :is-standalone="true" :list="giftlist"></list-spoiler-button>
                            </header>
                            <div class="list-grid" ref="listContainer" key="listGrid">
                                <slot name="list-grid-start"></slot>
                                <template v-for="item of $page.props.items?.data" :key="item.uuid" v-if="$page.props.items?.data.length > 0">
                                    <component :is="ItemCard" :initial-item="item" :has-lists="true" :item="item" :list="$page.props.giftlist?.data" :is-owner="$page.props.giftlist?.data.is_owner" />
                                </template>
                                <template v-else>
                                    <p class="list-reveal__sorry">No gifts have been purchased from this wish list yet. Why not share your wish list?</p>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-if="route().current() === 'list.show'">
                        <div class="list-grid" ref="listContainer" key="listGrid">
                            <slot name="list-grid-start"></slot>
                            <template v-for="item of $page.props.items?.data" :key="item.uuid">
                                <component :is="ItemCard" :initial-item="item" :has-lists="true" :item="item" :list="$page.props.giftlist?.data" :is-owner="$page.props.giftlist?.data.is_owner" />
                            </template>
                        </div>
                    </template>
                    <nav class="pagination whitespace-bottom" role="navigation" v-if="showPagination">
                            <ul>
                                <!--First page-->
                                <li>
                                    <span v-if="meta.current_page === 1">First Page</span>
                                    <Link :href="links.first" rel="first" v-else>First Page</Link>
                                </li>
                                <!--Previous page-->
                                <li class="page-prev">
                                    <Link :href="links.prev" class="btn-arrow btn-arrow--left" :class="{ 'btn-arrow--disabled': meta.current_page === 1 }" rel="prev">
                                        <svg-pagination-arrow></svg-pagination-arrow>
                                    </Link>
                                </li>
                                <!--Text-->
                                <li class="page-text">Page&nbsp;<strong>{{ meta.current_page }} of {{ meta.last_page }}</strong></li>
                                <!--TODO: Numbered page list (revisit in the future)-->
                                <li v-if="false">
                                    <ol>
                                        <li v-for="i in paginationData.total_pages">
                                            <a :href="buildPageUrl(i)" :class="isActivePage(i) ? 'is-active' : null" rel="i" @click.prevent="getPageByNumber(i)">{{ i }}</a>
                                        </li>
                                    </ol>
                                </li>
                                <!--Next page-->
                                <li class="page-next">
                                    <Link :href="links.next" class="btn-arrow" :class="{ 'btn-arrow--disabled': meta.current_page === meta.last_page }" rel="next">
                                        <svg-pagination-arrow></svg-pagination-arrow>
                                    </Link>
                                </li>
                                <!--Last page-->
                                <li>
                                    <span v-if="meta.current_page === meta.last_page">Last Page</span>
                                    <Link :href="links.last" rel="last" :disabled="true" v-else>Last Page</Link>
                                </li>
                            </ul>
                        </nav>
                    <slot name="list-grids-end"></slot>
                </section>
            </template>
        </SidebarLayout>
    </AppLayout>
</template>
<script setup>
import { computed, onMounted } from 'vue';
import AppLayout from "@/Layouts/AppLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SidebarLayout from "@/Layouts/SidebarLayout.vue";
import BackToWishLists from "@/Components/WishList/BackToWishLists.vue";
import ItemCard from "@/Components/Cards/Item/ItemCard.vue";
import ListSpoilerButton from "@/Components/Actions/ListSpoilerButton.vue";
import MastheadVisual from "@/Components/WishList/MastheadVisual.vue";
import { useAuthUser } from "@/composables/useAuthUser";
import { useWishList } from '@/composables/useWishList';
import SvgPaginationArrow from '~/svg/icon-pagination-arrow.svg';
import {usePage} from "@inertiajs/vue3";
import { useWishlistStore } from "@/stores/wishlist";

const wishlistStore = useWishlistStore();

defineOptions({
    layout: [BaseLayout],
});

const { giftlist, isExpertList, links, meta } = useWishList();
const owner = usePage().props.owner?.data;
const user =  useAuthUser();

const showPagination = computed(() => {
    if (route().current() === 'list.confirmed') {
        return wishlistStore.isSpoiled && meta.value.last_page > 1;
    } else {
        return meta?.value?.last_page > 1;
    }
});

onMounted(() => {
    if (route().current() === 'list.confirmed') {
        wishlistStore.showWishlistItems = false;
    } else {
        wishlistStore.showWishlistItems = true;
    }
});

const itemCardComponent = computed(() => {
    return ItemCard;
});

const ownerName = owner.presentation_name;
const openGraphData = usePage().props.giftlist?.data.open_graph;
const wishlistName = computed(() => giftlist.value.name);

// Meta Tags
const pageDescription = `${ownerName}'s &quot;${wishlistName.value}&quot; Wish List on Gift Hero`;
const ogTitle = `${ownerName}'s &quot;${wishlistName.value}&quot; Wish List`;
const ogDescription = computed(() => `Give ${ownerName} the perfect gift from their &quot;${wishlistName.value}&quot; wish list`);
const ogUrl = openGraphData.url;
const ogImage = openGraphData.share_image;
const noIndex = computed(() => {
    return usePage().props.giftlist?.data.privacy === 'private' || (!owner.is_gift_hero && !owner.is_organization);
});
</script>
