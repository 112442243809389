<!--suppress JSUnresolvedVariable -->
<template>
    <button class="popover__option strip-btn" @click="openMoveGiftToNewWishListModalog">
        <span class="icon"><icon-option-add/></span>
        <span class="label">{{ buttonText }}</span>
    </button>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import IconOptionAdd from '~/svg/ui-option-add.svg'

const modalogStore = useModalogStore();

const props = defineProps({
    item: {
        type: Object,
        default: null
    },
    isOwner: {
        type: Boolean,
        default: false
    }
});

const owner = ref(false);

const buttonText = computed(() => {
    return props.isOwner ? 'Move to New Wish List' : 'Copy to New Wish List';
});

onMounted(() => {
    /* If the item has a giftlist object, `item.giftlist.is_owner` will trump the `isOwner` prop. */
    owner.value = props.item.hasOwnProperty('giftlist') ? props.item.giftlist.is_owner : props.isOwner;
});

function openMoveGiftToNewWishListModalog () {
    modalogStore.showModalog({
        type: 'ModalogMoveCopyItemToNewWishList',
        props: {
            isOwner: props.isOwner,
            item: props.item
        },
        isConfirmDisabled: true
    });
}
</script>
