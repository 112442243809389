<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";

defineOptions({
    layout: [BaseLayout],
});
</script>
<template>
    <slot />
</template>
