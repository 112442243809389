import { defineStore } from 'pinia';
import { ref } from "vue";

export const useWishlistStore = defineStore('wishlist', () => {
    // Define your state here
    const isSpoiled = ref(false);
    const showWishlistItems = ref(true);
    const wishlistItems = ref([]);

    // Define your actions here
    function addItemToWishlist(item) {
        wishlistItems.value.push(item);
    }

    function removeItemFromWishlist(item) {
        const index = wishlistItems.value.findIndex(wishlistItem => wishlistItem.id === item.id);
        if (index !== -1) {
            wishlistItems.value.splice(index, 1);
        }
    }

    // Expose state and actions
    return {
        isSpoiled,
        showWishlistItems,
    }
});
