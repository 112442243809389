<template>
    <BaseCardOptionMenu :uuid="user.uuid" v-if="isVisible">
        <template #actions>
            <!--Unfollow-->
            <template v-if="user.following">
                <button class="popover__option strip-btn is-danger" @click.stop="unfollowUser">
                    <span class="icon"><svg-unfollow></svg-unfollow></span>
                    <span class="label">Stop Following</span>
                </button>
            </template>
            <hr class="popover__option--divider" v-if="user.following && !user.is_gift_hero">
            <BlockUnblockUserCardOption :user="props.user" />
        </template>
    </BaseCardOptionMenu>
</template>
<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useModalogStore } from "@/stores/modalog";
import BaseCardOptionMenu from "@/Components/OptionMenus/BaseCardOptionMenu.vue";
import BlockUnblockUserCardOption from "@/Components/OptionMenus/BlockUnblockUserCardOption.vue";
import SvgUnfollow from '~/svg/ui-option-unfollow.svg'

// Props
const props = defineProps({
   user: {
       type: Object,
       required: true,
   }
});

// State

// Composables
const { closeAll } = useCardOptionsMenuStore();
const { showModalog } = useModalogStore();

// Computed
const currentView = computed(() => {
    return route().current();
});

const isFollowersMine = computed(() => {
    return currentView.value === 'followers-mine';
});

const isGiftHero =  computed(() => {
    return user.is_gift_hero;
});

const isLoggedIn = computed(() => {
    return usePage().props.auth.user?.data;
});

const isVisible = computed(() => {
    return isLoggedIn;
});

// Methods
function unfollowUser () {
    showModalog({
        type: 'ModalogUnfollowConfirmation',
        props: {
            user: props.user,
        },
        isConfirmDisabled: false
    });
};
</script>
