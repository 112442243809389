<template>
    <button class="popover__option strip-btn" :disabled="isThrobbing" @click.stop="handleClick" v-if="($page.props.auth.user || props.loggedIn)">
      <span class="icon">
        <icon-option-bookmark-filled v-if="isBookmarked"></icon-option-bookmark-filled>
        <icon-option-bookmark v-else-if="!isBookmarked"></icon-option-bookmark>
      </span>
      <Throbber v-if="isThrobbing" />
      <span class="label" v-else>{{ statusText }}</span>
    </button>
    <Link :hrerf="route('login')" class="popover__option strip-btn" title="Login to Bookmark" v-else>Login to Bookmark</Link>
</template>
<script setup>
import { computed, ref } from 'vue';
import { router, usePage } from "@inertiajs/vue3";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import IconOptionBookmark from '~/svg/ui-option-bookmark.svg'
import IconOptionBookmarkFilled from '~/svg/ui-option-bookmark-filled.svg'
import Throbber from '@/utilities/Throbber.vue'

// Stores
const cardOptionsMenuStore = useCardOptionsMenuStore();

// Props
const props = defineProps({
    loggedIn: {
        type: Boolean,
        default: false
    },
    list: {
        type: Object,
        default: null
    },
});

// State
const actionCompletedText = ref(null);
const closeDelay = ref(1000);
const isBookmarked = ref(props.list?.is_bookmark ?? false);
const isThrobbing = ref(false);

// Computed
const isBookmarksView = computed(() => {
    return usePage().props.component === 'My Gift Hero/Bookmarks';
});

const statusText = computed(() => {
    return actionCompletedText.value ? actionCompletedText.value : isBookmarked.value ? 'Remove from Bookmarks' : 'Add to Bookmarks';
});

// Methods
/**
 * Delete a bookmark.
 */
function deleteBookmark () {
    isThrobbing.value = true;

    axios.delete(route('bookmark.destroy', { giftlist: props.list.uuid}), props.list.uuid)
        .then(() => {
            isBookmarked.value = false;
            props.list.is_bookmark = false;


            // TODO: This will all be handled by Inertia reload
            /* In Bookmarks view, show processing message and remove the card from view. */
            if (isBookmarksView.value) {
                props.list.is_processing = true;
                props.list.is_processing_text = `${props.list.name} Removed from Bookmarks`;
                cardOptionsMenuStore.closeAll(closeDelay.value);
                router.reload(({ only: ['bookmarks'] }));
            } else {
                /* Everywhere else, display the confirmation inline. */
                isBookmarked.value = false;
                props.list.is_bookmark = false;
                actionCompletedText.value = 'Removed from Bookmarks!';

                cardOptionsMenuStore.closeAll(closeDelay.value);

                setTimeout(() => {
                    resetActionCompletedText();
                }, closeDelay.value)
            }
        })
        .catch(error => console.error(error))
        .finally(() => isThrobbing.value = false);
};

/**
 * Determine which method to call on click event.
 */
function handleClick () {
    isBookmarked.value ? deleteBookmark() : saveBookmark();
};

function resetActionCompletedText () {
    setTimeout ( () => {
        actionCompletedText.value = null;
    }, 100)
};

/**
 * Save a bookmark.
 */
function saveBookmark () {
    isThrobbing.value = true;

    axios.post(route('bookmark.create', { giftlist: props.list.uuid }), props.list.uuid)
        .then(() => {
            isBookmarked.value = true;
            props.list.is_bookmark = true;
            actionCompletedText.value = 'Saved to Bookmarks!';

            cardOptionsMenuStore.closeAll(closeDelay.value);

            setTimeout(() => {
                resetActionCompletedText();
            }, closeDelay.value);
        })
        .catch(error => console.error(error))
        .finally(() => isThrobbing.value = false);
};
</script>
