<script setup>
import { computed } from "vue";
import { Link } from "@inertiajs/vue3";
import { isEmpty } from "lodash";
import SvgPaginationArrow from "~/svg/icon-pagination-arrow.svg";

const props =  defineProps({
    meta: {
        type: Object,
        required: true,
    },
    links: {
        type: Object,
        required: true,
    },
});

const isLastPage = computed(() => {
    return props.meta.current_page === props.meta.last_page;
});
</script>
<template>
    <nav class="pagination whitespace-bottom" role="navigation" v-show="!isEmpty(props.meta) && !isEmpty(props.links) && props.meta?.last_page > 1">
        <ul>
            <!--First page-->
            <li>
                <span v-if="props.meta.current_page === 1">First Page</span>
                <Link :href="props.links.first" rel="first" v-else>First Page</Link>
            </li>
            <!--Previous page-->
            <li class="page-prev">
                <span class="btn-arrow btn-arrow--disabled" v-if="props.meta.current_page === 1">
                    <svg-pagination-arrow></svg-pagination-arrow>
                </span>
                <Link :href="props.links.prev" class="btn-arrow btn-arrow--left" :class="{ 'btn-arrow--disabled': props.meta.current_page === 1 }" rel="prev" v-else>
                    <svg-pagination-arrow></svg-pagination-arrow>
                </Link>
            </li>
            <!--Text-->
            <li class="page-text">Page&nbsp;<strong>{{ props.meta.current_page }} of {{ props.meta.last_page }}</strong></li>
            <!--TODO: Numbered page list (revisit in the future)-->
            <li v-if="false">
                <ol>
                    <li v-for="i in paginationData.total_pages">
                        <a :href="buildPageUrl(i)" :class="isActivePage(i) ? 'is-active' : null" rel="i" @click.prevent="getPageByNumber(i)">{{ i }}</a>
                    </li>
                </ol>
            </li>
            <!--Next page-->
            <li class="page-next">
                <span class="btn-arrow btn-arrow--disabled" v-if="isLastPage">
                    <svg-pagination-arrow></svg-pagination-arrow>
                </span>
                <Link :href="props.links.next" class="btn-arrow" :class="{ 'btn-arrow--disabled': props.meta.current_page === props.meta.last_page }" rel="next" :disabled="isLastPage" v-else>
                    <svg-pagination-arrow></svg-pagination-arrow>
                </Link>
            </li>
            <!--Last page-->
            <li>
                <span v-if="props.meta.current_page === props.meta.last_page">Last Page</span>
                <Link :href="props.links.last" rel="last" :disabled="true" v-else>Last Page</Link>
            </li>
        </ul>
    </nav>
</template>
